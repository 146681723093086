import $api from "../api/api";

export default {
    login: async (email, password) => {
        return $api.post("/login", { email, password });
    },
    registration: async (email, password) => {
        return $api.post("/registration", { email, password });
    },
    logout: async () => {
        return $api.post("/logout");
    },
    checkAuth: async () => {
        return $api.get("/refresh");
    },
};
