import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { getComments } from "../store/commentsSlice";

export default function Comments() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getComments());
    }, []);
    return (
        <>
            <Outlet />
        </>
    );
}
