import { Box, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { logout } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    Settings,
    AccountCircle,
    Telegram,
    Logout,
    Groups,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function SetingsNavigate({ open, setOpen }) {
    const user = useSelector((state) => state.isAuth.user);
    const roles = useSelector((state) => state.isAuth.user.roles);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    let arrSettings = [
        {
            text: "Profile",
            url: `/teamsprofile/edit/${user.id}`,
            icon: <AccountCircle />,
            roles: ["ALL"],
            col: () => {},
        },
        {
            text: "Telegram",
            url: "messages",
            icon: <Telegram />,
            roles: ["ALL"],
            col: () => {},
        },
        {
            text: "Teams Profile",
            url: "teamsprofile",
            icon: <Groups />,
            roles: ["Admin"],
            col: () => {},
        },
        // { text: "Settings", icon: <Settings />, col: () => {} },
        {
            text: "logout",
            url: "",
            icon: <Logout />,
            roles: ["ALL"],
            col: () => logouts(),
        },
    ];

    const [settings, setSettings] = useState([]);

    React.useEffect(() => {
        let settings = [];
        if (roles === undefined) return;
        for (let i of arrSettings) {
            if (i.roles.includes("ALL")) {
                settings.push(i);
                continue;
            }
            if (i.roles.includes(roles)) {
                settings.push(i);
            }
        }
        setSettings(settings);
    }, [roles]);

    const logouts = () => {
        dispatch(logout());
    };
    return (
        <>
            <Box sx={{ flexGrow: 0 }}>
                <Menu
                    sx={{ mt: "45px" }}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    {settings.map((i, x) => {
                        return (
                            <MenuItem
                                key={x}
                                onClick={() => {
                                    i.col();
                                    setOpen(false);
                                    navigate(i.url);
                                }}
                            >
                                <ListItemIcon>{i.icon}</ListItemIcon>
                                <Typography textAlign="center">
                                    {i.text}
                                </Typography>
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Box>
        </>
    );
}
