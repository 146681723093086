import React, { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";

import {
    // AppBar,
    Avatar,
    Box,
    Button,
    IconButton,
    Toolbar,
    Tooltip,
    Typography,
} from "@mui/material";
import { Menu, Person, ArrowDropDown } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "navSiteOpen",
})(({ theme, navSiteOpen }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(navSiteOpen && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function Headers({
    navSiteOpen,
    handleDrawerNavigateOpen,
    setNavSetingOpen,
}) {
    const location = useLocation();
    const [loc, setLoc] = useState();
    const user = useSelector((state) => state.isAuth.user);
    useEffect(() => {
        setLoc(location.pathname.split("/")[1].toLocaleUpperCase());
    }, [location]);
    return (
        <>
            <AppBar
                // sx={{ zIndex: 9999 }}
                position="fixed"
                navSiteOpen={navSiteOpen}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="navSiteOpen drawer"
                        onClick={handleDrawerNavigateOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(navSiteOpen && { display: "none" }),
                        }}
                    >
                        <Menu />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        {loc}
                    </Typography>
                    <Tooltip title="Open settings">
                        <Button
                            variant="outlined"
                            onClick={() => setNavSetingOpen(true)}
                            sx={{ p: 0 }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    color: "#fff",
                                    fontSize: 15,
                                }}
                            >
                                {user.team} / {user.email}
                            </Typography>
                            <Person sx={{ ml: 1 }} color="white" />
                            <ArrowDropDown fontSize="large" color="white" />
                        </Button>
                    </Tooltip>
                </Toolbar>
            </AppBar>
        </>
    );
}
