import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProxyService from "../services/ProxyService.js";

export const getProxy = createAsyncThunk(
    "proxy/getProxy",
    async (action, { rejectWithValue }) => {
        try {
            const response = await ProxyService.getProxy();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const postProxy = createAsyncThunk(
    "proxy/postProxy",
    async (action, { rejectWithValue }) => {
        try {
            const response = await ProxyService.createProxy(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const EditProxy = createAsyncThunk(
    "proxy/EditProxy",
    async (action, { rejectWithValue }) => {
        try {
            const response = await ProxyService.updateProxy(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteProxy = createAsyncThunk(
    "proxy/deleteProxy",
    async (action, { rejectWithValue }) => {
        try {
            const response = await ProxyService.deleteProxy(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
const proxySlice = createSlice({
    name: "proxy",
    initialState: {
        louding: false,
        proxy: [],
        error: "",
    },
    reducers: {},
    extraReducers: {
        [getProxy.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [getProxy.fulfilled]: (state, action) => {
            state.proxy = action.payload;
            state.louding = false;
        },
        [getProxy.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [postProxy.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [postProxy.fulfilled]: (state, action) => {
            state.proxy = action.payload;
            state.louding = false;
            window.location = "/proxy";
        },
        [postProxy.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [EditProxy.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [EditProxy.fulfilled]: (state, action) => {
            state.proxy = action.payload;
            state.louding = false;
            window.location = "/proxy";
        },
        [EditProxy.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [deleteProxy.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [deleteProxy.fulfilled]: (state, action) => {
            state.proxy = action.payload;
            state.louding = false;
        },
        [deleteProxy.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
    },
});

export default proxySlice.reducer;
