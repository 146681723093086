import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import GroupsService from '../services/GroupsService'

export const getAllGroups = createAsyncThunk(
	'user/getAllGroups',
	async (action, { rejectWithValue }) => {
		try {
			const response = await GroupsService.getAllGroups()
			return response.data
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)

const groupsSlice = createSlice({
	name: 'groups',
	initialState: {
		groups: [],
		louding: false,
		error: '',
		user: {},
	},
	reducers: {},
	extraReducers: {
		[getAllGroups.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[getAllGroups.fulfilled]: (state, action) => {
			state.louding = false
			state.groups = action.payload
		},
		[getAllGroups.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
	},
})

export default groupsSlice.reducer
