import { configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import proxySlice from './proxySlice'
import accountsSlice from './accountsSlice'
import unicodeSlice from './unicodeSlice'
import commentsSlice from './commentsSlice'
import botSlice from './botSlice'
import userSlice from './userSlice'
import autoCostSlice from './autoCostSlice'
import groupsSlice from './groupsSlike'

export default configureStore({
	reducer: {
		isAuth: authSlice,
		user: userSlice,
		proxy: proxySlice,
		accounts: accountsSlice,
		unicode: unicodeSlice,
		comments: commentsSlice,
		bot: botSlice,
		autocost: autoCostSlice,
		groups: groupsSlice,
	},
})
