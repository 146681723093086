import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { AutoCostPostCookieFbTool } from "../../store/autoCostSlice";
import { useNavigate } from "react-router-dom";

export default function AutoCostTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const fbtool_cookie = useSelector((state) => state.autocost.cookie);
    const task = useSelector((state) => state.autocost.task);

    const [cookie, setCookie] = useState("");
    const [cookieStatus, setCookieStatus] = useState("");

    useEffect(() => {
        setCookie(fbtool_cookie?.cookie);
        setCookieStatus(fbtool_cookie?.status);
    }, [fbtool_cookie]);

    // const deleteTask = (id) => {
    //     dispatch(AutoCostDelTask(id));
    // };
    const addCookie = () => {
        try {
            let cc = "";
            for (let i of JSON.parse(cookie)) {
                cc += `${i.name}=${i.value};`;
            }
            dispatch(
                AutoCostPostCookieFbTool({
                    cookie_id: fbtool_cookie.id,
                    cookie: cc,
                })
            );
        } catch (error) {
            setCookie("");
        }
    };
    return (
        <>
            {cookieStatus === "active" && <>Ваши куки активны идите лесом</>}
            <Dialog sx={{ zIndex: 1 }} open={cookieStatus !== "active"}>
                <Box
                    m={"20px 0"}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    gap={2}
                    padding={3}
                >
                    <TextField
                        value={cookie}
                        onInput={(e) => setCookie(e.target.value)}
                        label="Cookie FbTool"
                    />
                    <Box>Status cookie: {cookieStatus}</Box>
                    <Button onClick={addCookie} variant="contained">
                        Добавить Куки
                    </Button>
                </Box>
            </Dialog>
        </>
    );
}
