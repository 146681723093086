import React, { useState } from "react";
import { Button } from "@mui/material";
import BotCreatePopUp from "../components/Bot/BotCreatePopUp/BotCreatePopUp";
import BotTable from "../components/Bot/BotTable/BotTable";

export default function Bot() {
    const [popOpen, setOpen] = useState(false);

    return (
        <>
            <Button
                variant="contained"
                color="success"
                onClick={() => {
                    setOpen(true);
                }}
            >
                Create
            </Button>
            <BotTable />
            <BotCreatePopUp open={popOpen} setOpen={setOpen} />
        </>
    );
}
