import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	AccountsaddError,
	getAccounts,
	postAccounts,
	updateAccounts,
} from '../../../store/accountsSlice.js'
import { getProxy } from '../../../store/proxySlice.js'
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControlLabel,
	MenuItem,
	TextField,
} from '@mui/material'
import { useParams } from 'react-router-dom'
export default function AccountsEdit() {
	const { id } = useParams()
	const dispatch = useDispatch()
	const [value, setValue] = useState({ label: '', value: '' })

	const proxy = useSelector(state => state.proxy.proxy)
	const unicodeSring = useSelector(state => state.unicode.unicode)
	const unicode = JSON.parse(unicodeSring)
	const accounts = useSelector(state => state.accounts.accounts)
	const groups = useSelector(state => state.groups.groups)

	const [proxyId, setProxyId] = useState('')
	const [proxyStatus, setProxyStatus] = useState(false)

	const [status, setStatus] = useState('')

	const [name, setName] = useState('')
	const [gender, setGender] = useState('')
	const [coockie, setCoockie] = useState('')
	const [login, setLogin] = useState('')
	const [pasword, setPasword] = useState('')
	const [userAgent, setUserAgent] = useState('')
	const [auto_login, setAuto_login] = useState(false)
	const [groups_id, setGroups_id] = useState(-1)
	useEffect(() => {
		const arr = accounts.filter(i => {
			return i.id == id
		})[0]
		if (arr) {
			setProxyId(arr.proxy_id)
			setStatus(arr.status)
			setName(arr.name)
			for (let i of unicode) {
				if (i.value == arr.unicode) {
					setValue(i)
				}
			}

			setGender(arr.gender)
			setCoockie(JSON.stringify(JSON.parse(arr.coockie)))
			setLogin(arr.login)
			setPasword(arr.pasword)
			setUserAgent(arr.user_agent)
			setGroups_id(arr.access_groups_id)
			if (arr.auto_login) {
				setAuto_login(true)
			}
		}
	}, [accounts, id])
	useEffect(() => {
		for (let i of proxy) {
			if (i.id == proxyId) {
				setProxyStatus(i.status)
			}
		}
	}, [proxyId, proxy])
	const handleSubmit = (event, st = false) => {
		event.preventDefault()
		try {
			const acounts = {
				accounts_id: id,
				name: name,
				status: +status,
				coockie: JSON.stringify(JSON.parse(coockie)),
				proxy_id: proxyId,
				login: login,
				pasword: pasword,
				unicode: value.value,
				gender: gender,
				userAgent: userAgent,
				autoLogin: auto_login,
				access_groups_id: groups_id,
			}
			dispatch(updateAccounts(acounts))
		} catch (e) {
			dispatch(AccountsaddError('ощибка симантики'))
		}
	}
	return (
		<>
			<Box
				onSubmit={handleSubmit}
				component={'form'}
				display={'flex'}
				gap={2}
				flexDirection={'column'}
			>
				<TextField
					value={name}
					onInput={e => setName(e.target.value)}
					required
					label='Name'
				/>
				<TextField
					error={+status}
					label='Status'
					select
					required
					value={+status}
					onChange={e => setStatus(+e.target.value)}
				>
					<MenuItem value={0}>Active</MenuItem>
					<MenuItem value={1}>logout</MenuItem>
					<MenuItem value={2}>Сheckpoint</MenuItem>
					<MenuItem value={3}>Archive</MenuItem>
				</TextField>
				<TextField
					label='Gender'
					select
					required
					value={gender}
					onChange={e => setGender(e.target.value)}
				>
					<MenuItem value={'M'}>Men</MenuItem>
					<MenuItem value={'W'}>Women</MenuItem>
				</TextField>
				<Autocomplete
					value={value}
					options={unicode}
					onChange={(value, newValue) => {
						if (newValue != null) {
							setValue(newValue)
						} else {
							setValue({ label: '', value: '' })
						}
					}}
					renderInput={params => <TextField {...params} label='Страна' />}
				/>

				<Divider sx={{ m: 2 }} />
				<TextField
					label='Proxy'
					select
					required
					value={proxyId}
					error={proxyStatus}
					onChange={e => setProxyId(e.target.value)}
				>
					{proxy.map(i => (
						<MenuItem
							key={i.name}
							value={i.id}
							sx={{ color: i.status ? 'red' : 'green' }}
						>
							{i.name}
						</MenuItem>
					))}
				</TextField>
				<TextField
					value={coockie}
					onInput={e => setCoockie(e.target.value)}
					required
					label='Cookie'
				/>
				<TextField
					value={login}
					onInput={e => setLogin(e.target.value)}
					required
					label='login'
				/>
				<TextField
					value={pasword}
					onInput={e => setPasword(e.target.value)}
					required
					label='Pasword'
				/>
				<TextField
					value={userAgent}
					onInput={e => setUserAgent(e.target.value)}
					label='User Agent'
				/>
				<FormControlLabel
					value='AutoLogin'
					control={
						<Checkbox
							onInput={e => {
								setAuto_login(!e.target.checked)
							}}
							checked={auto_login}
						/>
					}
					label='AutoLogin'
					labelPlacement='end'
				/>
				<Divider />
				<TextField
					label='Access Groups'
					select
					required
					value={groups_id}
					onChange={e => setGroups_id(e.target.value)}
				>
					<MenuItem value={-1}>ALL</MenuItem>
					{groups.map(i => (
						<MenuItem key={i.name} value={i.id}>
							{i.name}
						</MenuItem>
					))}
				</TextField>
				<Button variant='contained' type='submit'>
					Save
				</Button>
			</Box>
		</>
	)
}
