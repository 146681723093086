import { Box, MenuItem, TextField } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import React from "react";

export default function AccountsTableToolbar({
    arrStatusSort,
    StatusSort,
    setStatusSort,
    arrProxySort,
    ProxySort,
    setProxySort,
    arrCountrySort,
    CountrySort,
    setCountrySort,
}) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: 2,
                p: 0.5,
                // pb: 0,
                background: "#eee",
            }}
        >
            <GridToolbarQuickFilter
                variant="outlined"
                sx={{
                    width: "25%",
                    background: "#fff",
                    borderRadius: 1,
                    pb: 0,
                }}
            />
            <TextField
                sx={{
                    width: "25%",
                    background: "#fff",
                    borderRadius: 1,
                    pb: 0,
                }}
                onChange={(e) => setStatusSort(e.target.value)}
                value={StatusSort}
                label="Status"
                select
            >
                {arrStatusSort.map((i) => (
                    <MenuItem key={i} value={i}>
                        {i}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                sx={{
                    width: "25%",
                    background: "#fff",
                    borderRadius: 1,
                    pb: 0,
                }}
                onChange={(e) => setCountrySort(e.target.value)}
                value={CountrySort}
                label="Country"
                select
            >
                {arrCountrySort.map((i) => (
                    <MenuItem key={i} value={i}>
                        {i}
                    </MenuItem>
                ))}
            </TextField>
            <TextField
                sx={{
                    width: "25%",
                    background: "#fff",
                    borderRadius: 1,
                    pb: 0,
                }}
                onChange={(e) => setProxySort(e.target.value)}
                value={ProxySort}
                label="Proxy"
                select
            >
                {arrProxySort.map((i) => (
                    <MenuItem key={i} value={i}>
                        {i}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
}
