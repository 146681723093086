import React, { useEffect } from "react";
import LoginForm from "../components/LoginForm/LoginFotm";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "../store/authSlice";
import { useNavigate } from "react-router-dom";

export default function Auth() {
    const navigation = useNavigate();
    const auth = useSelector((state) => state.isAuth.isAuth);

    const dispatch = useDispatch();
    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(checkAuth());
        }
    }, []);
    useEffect(() => {
        if (auth) {
            navigation("/");
        }
    }, [auth]);
    return (
        <>
            <LoginForm />
        </>
    );
}
