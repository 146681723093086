import $api from "../api/api";

export default {
    getCookie: async () => {
        return await $api.get("/autocost/cookie");
    },
    poostCookie: async (i) => {
        return await $api.post("/autocost/cookie", i);
    },
    getPostFbtool: async () => {
        return await $api.get("/autocost/post");
    },
    // --
    postTask: async (i) => {
        return await $api.post("/autocost/task", i);
    },
    delTask: async (id) => {
        return await $api.delete("/autocost/task", { data: { task_id: id } });
    },
};
