import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BotService from "../services/BotService";
export const getBot = createAsyncThunk(
    "bot/getBot",
    async (action, { rejectWithValue }) => {
        try {
            const response = await BotService.getBot();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const postBot = createAsyncThunk(
    "bot/postBot",
    async (action, { rejectWithValue }) => {
        try {
            const response = await BotService.postBot(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateBotStatus = createAsyncThunk(
    "bot/updateBotStatus",
    async (action, { rejectWithValue }) => {
        try {
            const response = await BotService.updateBotStatus(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateBotNotes = createAsyncThunk(
    "bot/updateBotNotes",
    async (action, { rejectWithValue }) => {
        try {
            const response = await BotService.updateBotNotes(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteBot = createAsyncThunk(
    "bot/deleteBot",
    async (action, { rejectWithValue }) => {
        try {
            const response = await BotService.deleteBot(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
const commentsSlice = createSlice({
    name: "bot",
    initialState: {
        louding: false,
        bot: [],
        error: "",
    },
    reducers: {},
    extraReducers: {
        [postBot.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [postBot.fulfilled]: (state, action) => {
            state.bot = action.payload;
            state.louding = false;
        },
        [postBot.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateBotStatus.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateBotStatus.fulfilled]: (state, action) => {
            state.bot = action.payload;
            state.louding = false;
        },
        [updateBotStatus.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateBotNotes.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateBotNotes.fulfilled]: (state, action) => {
            state.bot = action.payload;
            state.louding = false;
        },
        [updateBotNotes.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [getBot.pending]: (state, action) => {
            // state.louding = true;
            state.error = "";
        },
        [getBot.fulfilled]: (state, action) => {
            state.bot = action.payload;
            state.louding = false;
        },
        [getBot.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [deleteBot.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [deleteBot.fulfilled]: (state, action) => {
            state.bot = action.payload;
            state.louding = false;
        },
        [deleteBot.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
    },
});

export default commentsSlice.reducer;
