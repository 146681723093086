import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTelegram } from "../store/authSlice";

export default function Messages() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.isAuth.user);
    return (
        <>
            {user.telgram ? (
                <Box>
                    <Typography color={"green"}>Telegram Added</Typography>
                    <Button
                        onClick={() => dispatch(deleteTelegram())}
                        variant="contained"
                        color="error"
                    >
                        Delete telegram
                    </Button>
                </Box>
            ) : (
                <Box>
                    <Typography color={"red"}>No Telegram</Typography>
                    <Button
                        href={`https://t.me/Auto_Coments_bot?start=${user.id}`}
                        target="_blank"
                        variant="contained"
                        color="success"
                    >
                        Add Telegram
                    </Button>
                </Box>
            )}
        </>
    );
}
