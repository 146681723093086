import CommentCreate from "../components/Comment/CommentCreate/CommentCreate";
import CommentEdit from "../components/Comment/CommentEdit/CommentEdit";
import CommentTable from "../components/Comment/CommentTable/CommentTable";
import Comments from "../pages/Comments";

export default {
    path: "/comments",
    element: <Comments />,
    children: [
        {
            index: true,
            element: <CommentTable />,
        },
        {
            path: "create",
            element: <CommentCreate />,
        },
        {
            path: "edit/:id",
            element: <CommentEdit />,
        },
    ],
};
