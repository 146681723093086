import $api from "../api/api";

export default {
    getBot: async () => {
        return $api.get("/bot");
    },
    postBot: async (bot) => {
        return $api.post("/bot", bot);
    },
    updateBotStatus: async (bot) => {
        return $api.put("bot/status", bot);
    },
    updateBotNotes: async (bot) => {
        return $api.put("bot/notes", bot);
    },
    deleteBot: async (id) => {
        return $api.delete("/bot", { data: { bot_id: id } });
    },
};
