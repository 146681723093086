import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/authSlice";
import { useParams } from "react-router-dom";
import { getAllUsersTeam, updateTeam } from "../../../store/userSlice";

export default function TeamProfileEdit() {
    const dispatch = useDispatch();
    const team_users = useSelector((state) => state.user.teamUsers);
    const user = useSelector((state) => state.isAuth.user);
    const [error, setError] = useState("");
    const { id } = useParams();

    useEffect(() => {
        dispatch(getAllUsersTeam());
    }, []);
    useEffect(() => {
        if (id == user.id) {
            setName(user.name);
            setEmail(user.email);
            setNewPassword(user.origin_password);
            setPasswordConfirmation(user.origin_password);
        } else {
            for (let i of team_users) {
                if (i.id == id) {
                    setName(i.name);
                    setEmail(i.email);
                    setNewPassword("");
                    setPasswordConfirmation("");
                }
            }
        }
    }, [team_users]);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [passwordMatch, setPasswordMatch] = useState(false);

    useEffect(() => {
        setError("");
        if (newPassword && newPassword === passwordConfirmation) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }, [passwordConfirmation, newPassword]);

    const hendlerSubmit = (e) => {
        e.preventDefault();
        if (newPassword) {
            if (newPassword !== passwordConfirmation) {
                setError("Not confirmed!");
                return;
            }
        }
        const users = {
            user_id: id,
            name: name,
            email: email,
            newPassword: newPassword,
        };
        if (id == user.id) {
            dispatch(updateUser(users));
        } else {
            dispatch(updateTeam(users));
        }
    };
    return (
        <>
            <Box
                onSubmit={hendlerSubmit}
                component={"form"}
                display={"flex"}
                flexDirection={"column"}
                gap={3}
            >
                <TextField
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                    label="Name"
                />
                <TextField
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                    label="Email"
                    disabled
                />
                <TextField
                    value={newPassword}
                    onInput={(e) => setNewPassword(e.target.value)}
                    label="New Password"
                />
                {newPassword && (
                    <TextField
                        required={Boolean(newPassword)}
                        error={!passwordMatch}
                        value={passwordConfirmation}
                        onInput={(e) => setPasswordConfirmation(e.target.value)}
                        label="Password Confirmation"
                    />
                )}
                {error && <Typography color="error">{error}</Typography>}
                <Box display={"flex"} justifyContent={"center"}>
                    <Button type="submit" variant="contained" color="success">
                        Update Profile
                    </Button>
                </Box>
            </Box>
        </>
    );
}
