import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAccounts } from '../../../store/accountsSlice'
import CommentPopUpCreate from '../CommentPopUpCreate/CommentPopUpCreate'
import CommentPopUpEdit from '../CommentPopUpEdit/CommentPopUpEdit'

import CommentScheme from '../CommentScheme/CommentScheme'
import { postComments } from '../../../store/commentsSlice'

export default function CommentCreate() {
	const dispatch = useDispatch()
	const userId = useSelector(state => state.isAuth.user.id)

	useEffect(() => {
		dispatch(getAccounts())
	}, [])
	// --
	const [user_id, setUser_id] = useState(userId)
	const [visibility, setVisibility] = useState(0)
	const [name, setName] = useState('')
	const [comen, setComen] = useState([])
	const [coutry, setCoutry] = useState(null)
	//
	const [variant, setVariant] = useState(1)
	const [ids, seIds] = useState(1)
	const [openCreate, setOpenCrwate] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)
	const openPopUpCreateComent = (v, id) => {
		setVariant(v)
		seIds(id)
		setOpenCrwate(true)
	}
	const openPopUpEditComent = id => {
		seIds(id)
		setOpenEdit(true)
	}

	const deleteComments = (i, v) => {
		let arr = []
		let chr = false
		for (let f of comen) {
			if (f.id == i && !chr) {
				chr = true
				arr.push(f.id)
				continue
			}
			if (chr && f.variant > v) {
				arr.push(f.id)
			} else if (chr) {
				break
			}
		}
		setComen(
			comen.filter(b => {
				for (let i of arr) {
					if (i == b.id) return false
				}
				return true
			})
		)
	}
	const handleSubmit = event => {
		event.preventDefault()
		const com = comen.map((i, x) => {
			let answer = null
			if (i.variant != 1) {
				for (let s = x - 1; s > -1; s--) {
					if (comen[s].variant < i.variant) {
						answer = s
						break
					}
				}
			}
			return {
				text: i.text,
				photo: i.photo_id ? i.photo_id : i.photo,
				variant: i.variant,
				position: x,
				answer: answer,
				fb_account_id: i.fb_account_id,
				like_0: i.like_0,
				like_1: i.like_1,
				like_2: i.like_2,
				like_3: i.like_3,
			}
		})
		const data = new FormData(event.currentTarget)
		const commentss = new FormData()
		comen.map(i => {
			commentss.append(i.photo_id, i.photo)
		})
		commentss.append('name', data.get('name'))
		commentss.append('visibility', visibility)
		commentss.append('unicode', coutry)
		commentss.append('text', JSON.stringify(com))
		dispatch(postComments(commentss))
	}
	// --
	const propsScheme = {
		name,
		comen,
		coutry,
		setName,
		setCoutry,
		openPopUpCreateComent,
		openPopUpEditComent,
		deleteComments,
		handleSubmit,
		visibility,
		setVisibility,
		user_id,
	}
	const propsPopUpCreate = {
		ids,
		variants: variant,
		comen,
		open: openCreate,
		setComen,
		setOpen: setOpenCrwate,
	}
	const propsPopUpEdit = {
		comen,
		setComen,
		open: openEdit,
		setOpen: setOpenEdit,
		ids,
	}
	return (
		<>
			<CommentScheme {...propsScheme} />
			<CommentPopUpCreate {...propsPopUpCreate} />
			<CommentPopUpEdit {...propsPopUpEdit} />
		</>
	)
}
