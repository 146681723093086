import React from "react";
import setapBotImg from "../images/setapBot.jpg";

export default function SetapBot() {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "50px",
                }}
            >
                <img
                    style={{ width: "300px", borderRadius: "50%" }}
                    src={setapBotImg}
                    alt=""
                />
                <div>Тут будет бот для автоматического создания сылок</div>
            </div>
        </>
    );
}
