import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccounts } from "../../store/accountsSlice";
import { getComments_massage } from "../../store/commentsSlice";
import StatusModel from "../StatusModel/StatusModel";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";

export function InfoAcountPopUp({ open, close, comentId }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const massage = useSelector((state) => state.comments.massage);
    const accounts = useSelector((state) => state.accounts.accounts);

    const [popUpOpen, setpopUpOpen] = useState(false);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (open) {
            dispatch(getAccounts());
            dispatch(getComments_massage({ coment_id: comentId }));
        }
    }, [open, comentId]);
    useEffect(() => {
        if (open) {
            let acc = [];
            let results = [];
            for (let i of massage) {
                if (!acc.includes(i.fb_account_id)) {
                    acc.push(i.fb_account_id);
                }
            }
            for (let i of accounts) {
                if (acc.includes(i.id)) {
                    results.push(i);
                }
            }
            setRows(results);
            setpopUpOpen(true);
        } else {
            setpopUpOpen(false);
        }
    }, [massage, rows]);

    const hendleClose = () => {
        close();
        // setRows([]);
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "numder",
            editable: false,
            flex: 0.5,
        },
        {
            field: "name",
            headerName: "Name",
            type: "string",
            flex: 2,
            editable: false,
            renderCell: (params) => {
                const name = params.row.name;
                const id = params.row.id;
                return (
                    <Box
                        onClick={() => navigate(`/accounts/edit/${id}`)}
                        sx={{
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        {name}
                    </Box>
                );
            },
        },
        {
            field: "unicode",
            headerName: "Country",
            type: "singleSelect",
            editable: false,
            flex: 1,
        },
        {
            field: "usAgent",
            headerName: "User Agent",
            type: "boolean",
            editable: false,
            flex: 1,
        },
        {
            field: "autoLogin",
            headerName: "Auto Login",
            type: "boolean",
            editable: false,
            flex: 1,
        },
        {
            field: "proxy",
            headerName: "Proxy",
            type: "string",
            editable: false,
            flex: 2,
            renderCell: (params) => {
                const st = params.row.proxy_status;
                const pr = params.row.proxy_name;

                return (
                    <Box
                        sx={{
                            display: "flex",
                            borderRadius: 3,
                            border: "1px solid",
                            borderColor: st ? "red" : "green",
                            color: st ? "red" : "green",
                            padding: "5px",
                        }}
                    >
                        {pr}
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            type: "singleSelect",
            editable: false,
            valueOptions: StatusModel.AccountStatus(0, true),
            flex: 2,
            renderCell: (params) => {
                const st = params.row.status;
                return StatusModel.AccountStatus(st);
            },
        },
    ];

    return (
        <Dialog open={popUpOpen} onClose={hendleClose} fullWidth>
            <DialogContent>
                <Table
                    rows={rows}
                    columns={columns}
                    columnVisibilityModel={{
                        usAgent: false,
                        autoLogin: false,
                        id: false,
                    }}
                    popUp={"InfoAcountPopUp"}
                />
            </DialogContent>
        </Dialog>
    );
}
