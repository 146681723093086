import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { EditProxy, getProxy, postProxy } from "../../../store/proxySlice";
import { useParams } from "react-router-dom";
export default function ProxyEdit() {
    const { id } = useParams();

    const proxy = useSelector((state) => state.proxy.proxy);
    const error = useSelector((state) => state.proxy.error);

    const [name, setName] = useState("");
    const [protocol, setProtocol] = useState("");
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [ip, setIp] = useState("");
    const [port, setPort] = useState("");

    useEffect(
        (e) => {
            for (let i of proxy) {
                if (i.id == id) {
                    setName(i.name);
                    setProtocol(i.protocol);
                    setLogin(i.login);
                    setPassword(i.pasword);
                    setIp(i.ip);
                    setPort(i.port);
                    break;
                }
            }
        },
        [proxy]
    );
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        const proxy = {
            proxyId: id,
            protocol: protocol,
            login: login,
            password: password,
            ip: ip,
            port: port,
            name: name,
        };
        dispatch(EditProxy(proxy));
    };
    return (
        <Box
            onSubmit={handleSubmit}
            component={"form"}
            display={"flex"}
            gap={2}
            flexDirection={"column"}
        >
            <TextField
                value={name}
                onInput={(e) => setName(e.target.value)}
                required
                label="Name"
            />
            <Box>
                {protocol}://{login}:{password}@{ip}:{port}
            </Box>
            <Divider />
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="ProtocolLable">Protocol</InputLabel>
                <Select
                    labelId="ProtocolLable"
                    value={protocol}
                    onChange={(e) => setProtocol(e.target.value)}
                >
                    <MenuItem value="socks4">Socks 4</MenuItem>
                    <MenuItem value="socks5">Socks 5</MenuItem>
                    <MenuItem value="http">HTTP</MenuItem>
                </Select>
            </FormControl>
            <TextField
                value={login}
                onInput={(e) => setLogin(e.target.value)}
                required
                label="Login"
            />
            <TextField
                value={password}
                onInput={(e) => setPassword(e.target.value)}
                required
                label="Password"
            />
            <TextField
                value={ip}
                onInput={(e) => setIp(e.target.value)}
                required
                label="IP"
            />
            <TextField
                value={port}
                onInput={(e) => setPort(e.target.value)}
                required
                label="Port"
            />
            <Button variant="contained" type="submit">
                Save
            </Button>
        </Box>
    );
}
