import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateUserTeam } from "../../../store/userSlice";
export default function TeamProfileCreate() {
    const dispatch = useDispatch();
    const [error, setError] = useState("");

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [passwordMatch, setPasswordMatch] = useState(false);

    useEffect(() => {
        setError("");
        if (Password && Password === passwordConfirmation) {
            setPasswordMatch(true);
        } else {
            setPasswordMatch(false);
        }
    }, [passwordConfirmation, Password]);

    const hendlerSubmit = (e) => {
        e.preventDefault();
        if (Password) {
            if (Password !== passwordConfirmation) {
                setError("Not confirmed!");
                return;
            }
        }
        const users = {
            name: name,
            email: email,
            Password: Password,
        };
        dispatch(CreateUserTeam(users));
    };
    return (
        <>
            <Box
                onSubmit={hendlerSubmit}
                component={"form"}
                display={"flex"}
                flexDirection={"column"}
                gap={3}
            >
                <TextField
                    value={name}
                    onInput={(e) => setName(e.target.value)}
                    label="Name"
                    required
                />
                <TextField
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                    label="Email"
                    type="email"
                    required
                />
                <TextField
                    value={Password}
                    onInput={(e) => setPassword(e.target.value)}
                    label="Password"
                    required
                />
                {Password && (
                    <TextField
                        required={Boolean(Password)}
                        error={!passwordMatch}
                        value={passwordConfirmation}
                        onInput={(e) => setPasswordConfirmation(e.target.value)}
                        label="Password Confirmation"
                    />
                )}
                {error && <Typography color="error">{error}</Typography>}
                <Box display={"flex"} justifyContent={"center"}>
                    <Button type="submit" variant="contained" color="success">
                        Create User
                    </Button>
                </Box>
            </Box>
        </>
    );
}
