import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AutoCostGetTask } from "../../store/autoCostSlice";
import { useNavigate } from "react-router-dom";

export default function AutoCostCreate() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fbtool_cookie = useSelector((state) => state.autocost.cookie);

    useEffect(() => {
        if (fbtool_cookie.status !== "active") return navigate("../");
        dispatch(AutoCostGetTask());
    }, []);

    return (
        <>
            <Box>
                <Box>
                    <TextField select></TextField>
                </Box>
            </Box>
        </>
    );
}
