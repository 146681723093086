import $api from '../api/api'

export default {
	getAccounts: async () => {
		return $api.get('/accounts')
	},
	createAccounts: async ({
		name,
		coockie,
		proxy_id,
		login,
		pasword,
		unicode,
		gender,
		userAgent,
		autoLogin,
		access_groups_id,
	}) => {
		return $api.post('/accounts', {
			name,
			coockie,
			proxy_id,
			login,
			pasword,
			unicode,
			gender,
			userAgent,
			autoLogin,
			access_groups_id,
		})
	},
	deleteAccounts: async id => {
		return $api.delete('/accounts', { data: { account_id: id } })
	},
	updateAccounts: async ({
		name,
		coockie,
		proxy_id,
		login,
		pasword,
		unicode,
		gender,
		accounts_id,
		userAgent,
		autoLogin,
		status,
		access_groups_id,
	}) => {
		return $api.put('/accounts', {
			name,
			coockie,
			proxy_id,
			login,
			pasword,
			unicode,
			gender,
			accounts_id,
			userAgent,
			autoLogin,
			status,
			access_groups_id,
		})
	},
	updateAccountsNotes: async ({ accounts_id, notes }) => {
		return $api.put('/accounts/notes', {
			accounts_id,
			notes,
		})
	},
	updateAccountsStatus: async ({ accounts_id, status }) => {
		return $api.put('/accounts/status', {
			accounts_id,
			status,
		})
	},
}
