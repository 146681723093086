import React, { useEffect, useState } from "react";
import "./AccountsTable.css";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteAccounts,
    getAccounts,
    updateAccounts,
    updateAccountsNoMoving,
    updateAccountsNotesNoMoving,
} from "../../../store/accountsSlice";
import { useNavigate } from "react-router-dom";
import { Cancel, Delete, Feed, OpenInNew, Save } from "@mui/icons-material";
import {
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
} from "@mui/x-data-grid";
import StatusModel from "../../StatusModel/StatusModel";
import СonsentPopUp from "../../PopUp/СonsentPopUp";
import Table from "../../Table/Table.jsx";
import AccountsTableToolbar from "./AccountsTableToolbar.jsx";
import Cookies from "js-cookie";
import NotesPopUp from "../../PopUp/NotesPopUp.jsx";

export default function AccountsTable() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const accounts = useSelector((state) => state.accounts.accounts);

    const [popOpen, setPopOpen] = useState(false);
    const [deleteID, setDeleteID] = useState();
    const [rows, setRows] = useState([]);
    const [popNotesOpen, setPopNotesOpen] = useState(false);

    const [CountrySort, setCountrySort] = useState("All Country");
    const [ProxySort, setProxySort] = useState("All Proxy");
    const [StatusSort, setStatusSort] = useState("Active + Error");

    const [arrCountrySort, setArrCountrySort] = useState([]);
    const [arrProxySort, setArrProxySort] = useState([]);
    const [arrStatusSort, setArrStatusSort] = useState([
        "All Status",
        "Active",
        "Active + Error",
        "Error",
        "Archive",
    ]);

    const popClose = () => {
        setPopOpen(false);
    };

    useEffect(() => {
        dispatch(getAccounts());
    }, []);

    const getAccountsName = (id) => {
        for (let i of accounts) {
            if (i.id === id) {
                return `${i.unicode} - ${i.name} - ${i.gender}`;
            }
        }
    };

    useEffect(() => {
        let country = ["All Country"];
        let proxy = ["All Proxy"];
        let rr = [];
        for (let i of accounts) {
            if (!country.includes(i.unicode)) country.push(i.unicode);
            if (!proxy.includes(i.proxy_name)) proxy.push(i.proxy_name);
            if (StatusSort !== "All Status") {
                if (StatusSort === "Active" && i.status !== 0) continue;
                if (StatusSort === "Active + Error" && i.status === 3) continue;
                if (
                    StatusSort === "Error" &&
                    (i.status === 0 || i.status === 3)
                )
                    continue;
                if (StatusSort === "Archive" && i.status !== 3) continue;
            }
            if (CountrySort !== "All Country") {
                if (CountrySort !== i.unicode) continue;
            }
            if (ProxySort !== "All Proxy") {
                if (ProxySort !== i.proxy_name) continue;
            }
            let arr = { ...i };
            arr.dat = new Date(i.data);
            rr.push(arr);
        }
        if (!rr.length)
            rr.push({
                id: 0,
                name: "Увы но тут ничего нет",
                country: "-----",
                usAgent: "-----",
                autoLogin: "-----",
                proxy: "-----",
                status: "-----",
            });
        setRows(rr);
        setArrCountrySort(country);
        setArrProxySort(proxy);
    }, [accounts, StatusSort, ProxySort, CountrySort]);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            type: "numder",
            editable: false,
            getApplyQuickFilterFn: false,
            resizable: false,
            width: 20,
        },
        {
            field: "name",
            headerName: "Name",
            type: "string",
            flex: 2,
            editable: false,
            renderCell: (params) => getAccountsName(params.row.id),
        },
        {
            field: "unicode",
            headerName: "Country",
            type: "singleSelect",
            editable: false,
            resizable: false,
            width: 50,
        },
        {
            field: "user_agent",
            headerName: "User Agent",
            type: "boolean",
            editable: false,
            resizable: false,
            width: 50,
        },
        {
            field: "auto_login",
            headerName: "Auto Login",
            type: "boolean",
            editable: true,
            resizable: false,
            width: 50,
        },
        {
            field: "proxy_name",
            headerName: "Proxy",
            type: "string",
            editable: false,
            resizable: false,
            width: 200,
            renderCell: (params) => {
                const st = params.row.proxy_status;
                const pr = params.row.proxy_name;
                return (
                    <Box
                        sx={{
                            display: "flex",
                            borderRadius: 3,
                            border: "1px solid",
                            borderColor: st ? "red" : "green",
                            color: st ? "red" : "green",
                            padding: "5px",
                        }}
                    >
                        {pr}
                    </Box>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            type: "singleSelect",
            editable: true,
            resizable: false,
            valueOptions: StatusModel.AccountStatus(0, true),
            width: 200,
            renderCell: (params) =>
                StatusModel.AccountStatus(
                    params.row.status,
                    false,
                    params.row.last_update
                ),
        },
        {
            field: "last_update",
            headerName: "Last Update",
            align: "left",
            type: "string",
            editable: false,
            resizable: false,
            width: 50,
        },
        {
            field: "dat",
            headerName: "Last Update Time",
            type: "dateTime",
            editable: false,
            resizable: false,
            flex: 2,
        },
        {
            field: "login",
            headerName: "Login",
            type: "string",
            editable: true,
            flex: 2,
            renderCell: (params) => {
                return <Typography noWrap>{params.row.login}</Typography>;
            },
        },
        {
            field: "pasword",
            headerName: "Pasword",
            type: "string",
            editable: true,
            flex: 2,
            renderCell: (params) => {
                return <Typography noWrap>{params.row.pasword}</Typography>;
            },
        },
        {
            field: "actions",
            type: "actions",
            headerName: "Actions",
            width: 100,
            resizable: false,
            cellClassName: "actions",
            getActions: (params) => {
                const id = params.row.id;
                const isInEditMode =
                    rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<Save />}
                            label="Save"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<Feed color="info" />}
                        title={params.row.notes}
                        label="Notes"
                        onClick={() => {
                            setDeleteID(id);
                            FNpopNotesOpen();
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<OpenInNew />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => navigate(`edit/${id}`)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<Delete />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];
    const Toolbar = () => {
        return (
            <AccountsTableToolbar
                arrStatusSort={arrStatusSort}
                StatusSort={StatusSort}
                setStatusSort={setStatusSort}
                arrProxySort={arrProxySort}
                ProxySort={ProxySort}
                setProxySort={setProxySort}
                arrCountrySort={arrCountrySort}
                CountrySort={CountrySort}
                setCountrySort={setCountrySort}
            />
        );
    };

    const [rowModesModel, setRowModesModel] = useState({});

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
    };

    const handleDeleteClick = (id) => () => {
        setDeleteID(id);
        setPopOpen(true);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow) => {
        const acounts = {
            accounts_id: newRow.id,
            name: newRow.name,
            status: newRow.status,
            coockie: newRow.coockie,
            proxy_id: newRow.proxy_id,
            login: newRow.login,
            pasword: newRow.pasword,
            unicode: newRow.unicode,
            gender: newRow.gender,
            userAgent: newRow.user_agent,
            autoLogin: newRow.auto_login,
        };
        dispatch(updateAccountsNoMoving(acounts));
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const FNpopNotesClose = () => {
        setPopNotesOpen(false);
    };
    const FNpopNotesOpen = () => {
        setPopNotesOpen(true);
    };
    const getNotes = (id) => {
        for (let i of accounts) {
            if (id == i.id) {
                return i.notes;
            }
        }
        return "";
    };
    const SaveNotes = (text) => {
        dispatch(
            updateAccountsNotesNoMoving({
                accounts_id: deleteID,
                notes: text,
            })
        );
    };

    return (
        <>
            <Button
                variant="contained"
                color="success"
                onClick={() => navigate("create")}
            >
                Create
            </Button>
            <Table
                rows={rows}
                columns={columns}
                slots={{ toolbar: Toolbar }}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
            <СonsentPopUp
                open={popOpen}
                close={popClose}
                title={"Удалить Акаунт ?"}
                message={getAccountsName(deleteID)}
                success={() => {
                    popClose();
                    dispatch(deleteAccounts(deleteID));
                }}
                error={popClose}
            />
            <NotesPopUp
                open={popNotesOpen}
                close={FNpopNotesClose}
                basText={getNotes(deleteID)}
                save={SaveNotes}
            />
        </>
    );
}
