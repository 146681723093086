import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { router } from "./router/router";

const defaultTheme = createTheme({
    palette: {
        primary: {
            light: "#757ce8",
            main: "#3f50b5",
            dark: "#002884",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff7961",
            main: "#f44336",
            dark: "#ba000d",
            contrastText: "#000",
        },
        white: {
            light: "#fff",
            main: "#fff",
            dark: "#fff",
            contrastText: "#fff",
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <ThemeProvider theme={defaultTheme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </Provider>
);
