import Statistics from "../pages/Statistics";

export const StatisticsRouter = {
    path: "/statistics",
    element: <Statistics />,
    children: [
        // {
        //     index: true,
        //     element: <TeamProfileTable />,
        // },
    ],
};
