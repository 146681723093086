import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AccountsService from "../services/AccountsService";

export const getAccounts = createAsyncThunk(
    "accounts/getAccounts",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.getAccounts();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const postAccounts = createAsyncThunk(
    "accounts/postAccounts",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.createAccounts(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateAccounts = createAsyncThunk(
    "accounts/updateAccounts",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.updateAccounts(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateAccountsNotesNoMoving = createAsyncThunk(
    "accounts/updateAccountsNotesNoMoving",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.updateAccountsNotes(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateAccountsNoMoving = createAsyncThunk(
    "accounts/updateAccountsNoMoving",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.updateAccounts(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateAccountsStatus = createAsyncThunk(
    "accounts/updateAccountsStatus",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.updateAccountsStatus(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteAccounts = createAsyncThunk(
    "accounts/deleteAccounts",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AccountsService.deleteAccounts(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
const AccountsSlice = createSlice({
    name: "accounts",
    initialState: {
        louding: false,
        accounts: [],
        error: "",
    },
    reducers: {
        AccountsaddError(state, action) {
            state.error = action.payload;
        },
    },
    extraReducers: {
        [getAccounts.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [getAccounts.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
        },
        [getAccounts.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [postAccounts.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [postAccounts.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
            window.location = "/accounts";
        },
        [postAccounts.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateAccounts.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateAccounts.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
            window.location = "/accounts";
        },
        [updateAccounts.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateAccountsNotesNoMoving.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateAccountsNotesNoMoving.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
        },
        [updateAccountsNotesNoMoving.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateAccountsNoMoving.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateAccountsNoMoving.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
        },
        [updateAccountsNoMoving.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateAccountsStatus.pending]: (state, action) => {
            // state.louding = true;
            state.error = "";
        },
        [updateAccountsStatus.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
        },
        [updateAccountsStatus.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [deleteAccounts.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [deleteAccounts.fulfilled]: (state, action) => {
            state.accounts = action.payload;
            state.louding = false;
        },
        [deleteAccounts.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
    },
});
export const { AccountsaddError } = AccountsSlice.actions;
export default AccountsSlice.reducer;
