import {
    Autocomplete,
    Box,
    Dialog,
    IconButton,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { v4 } from "uuid";

import FacebookLike from "../../../images/Facebook_Like.png";
import FacebookLove from "../../../images/Facebook_Love.svg";
import FacebookCore from "../../../images/Facebook_Core.png";
import FacebookWow from "../../../images/Facebook_Wow.svg";

export default function CommentPopUpEdit({
    comen,
    setComen,
    open,
    setOpen,
    ids,
}) {
    const accounts = useSelector((state) => state.accounts.accounts);
    const [account, setAccount] = useState([]);
    const [accountValue, setAccountValue] = useState({
        label: "",
        value: "",
        status: "",
    });
    const [coment, setComent] = useState([]);

    const [like, setLike] = useState(0);
    const [love, setLove] = useState(0);
    const [core, setCore] = useState(0);
    const [wow, setWow] = useState(0);
    const [maxLike, setMaxLike] = useState(0);
    const [activeLike, setActiveLike] = useState(true);
    useEffect(() => {
        let unick_acount = [];
        for (let i of comen) {
            if (!unick_acount.includes(i.fb_account_id)) {
                unick_acount.push(i.fb_account_id);
            }
        }
        const sum = +like + +love + +core + +wow;
        setMaxLike(unick_acount.length);
        if (unick_acount.length <= sum) {
            setActiveLike(false);
        } else {
            setActiveLike(true);
        }
    }, [like, love, core, wow, comen]);

    useEffect(() => {
        for (let i of comen) {
            if (i.id == ids) {
                setComent(i);
                setText(i.text);
                setAccount_id(i.fb_account_id);
                setEditPhoto(false);
                if (i.photo != "null") {
                    if (typeof i.photo == "object") {
                        setPhoto("null");
                        setEditPhoto(true);
                        setTimeout(() => {
                            const file = i.photo;
                            let dt = new DataTransfer();
                            dt.items.add(file);
                            var file_list = dt.files;
                            if (document.getElementById("photo")) {
                                document.getElementById("photo").files =
                                    file_list;
                            } else {
                                // document.getElementById("photo").target.value =
                                //     "";
                            }
                        }, 500);
                    } else {
                        setPhoto(i.photo);
                    }
                } else {
                    setPhoto("null");
                }
                setLike(i.like_0);
                setLove(i.like_1);
                setCore(i.like_2);
                setWow(i.like_3);
                for (let b of accounts) {
                    if (b.id == i.fb_account_id || b.status < 3) {
                        setAccountValue({
                            label: `${b.name} - ${b.gender} - ${b.unicode}`,
                            value: b.id,
                            status: b.status,
                        });
                    }
                }
                break;
            }
        }
    }, [ids, open]);

    useEffect(() => {
        let mas = [];
        for (let i of accounts) {
            if (i.status < 3) {
                mas.push({
                    label: `${i.name} - ${i.gender} - ${i.unicode}`,
                    value: i.id,
                    status: i.status,
                });
            }
        }
        setAccount(mas);
    }, [accounts]);

    const [account_id, setAccount_id] = useState();
    const [text, setText] = useState();
    const [photo, setPhoto] = useState("null");
    const [editPhoto, setEditPhoto] = useState(false);
    const editComen = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let photos = "null";
        let photo_id = "null";
        if (editPhoto) {
            photos = data.get("photo").name ? data.get("photo") : "null";
            photo_id = data.get("photo").name
                ? v4().split("-")[0] +
                  "_" +
                  data.get("photo").name.split(" ").join("")
                : "null";
        } else {
            photos = photo;
            photo_id = "null";
        }
        const edit_text = (text) => {
            return text
                .split('"')
                .join("`")
                .split("'")
                .join("`")
                .split("\n")
                .join(" ")
                .split("\\")
                .join("")
                .split("\t")
                .join(" ")
                .split("&")
                .join(" ")
                .replace(/[\n\\\t\&]/, "");
        };
        const come = {
            id: coment.id,
            text: edit_text(text),
            photo: photos,
            variant: coment.variant,
            fb_account_id: account_id,
            like_0: +like,
            like_1: +love,
            like_2: +core,
            like_3: +wow,
        };

        if (photo_id != "null") {
            come.photo_id = photo_id;
        }
        let res = [];
        for (let i of comen) {
            if (i.id == ids) {
                res.push(come);
            } else {
                res.push(i);
            }
        }
        setComen(res);
        setOpen(false);
        setText("");
    };
    const ssPhoto = (e) => {
        setEditPhoto(true);
    };
    const closed = () => {
        setOpen(false);
        setText("");
        setLike(0);
        setLove(0);
        setCore(0);
        setWow(0);
    };
    function validate(elem) {
        const name = elem.target.files[0].name;
        var alphaExp = /^([a-z_A-Z\-\s0-9\.]+)+\.(png|gif|jpg|jpeg)$/;
        if (name.match(alphaExp)) {
            return true;
        } else {
            alert(
                "In the title of the photo, use English and numbers without special characters. Acceptable format: png, jpg, jpeg"
            );
            elem.target.value = "";
            return false;
        }
    }
    return (
        <>
            <Dialog onClose={closed} open={open}>
                <div style={{ textAlign: "center" }}>
                    {coment.variant == 1 && "Коментарий"}
                    {coment.variant == 2 && "Вопрос"}
                    {coment.variant == 3 && "Ответ"}
                </div>
                <Box
                    id="form"
                    onSubmit={editComen}
                    component={"form"}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "300px",
                        height: "550px",
                        padding: 2,
                    }}
                >
                    <Autocomplete
                        disablePortal
                        value={accountValue}
                        options={account}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <Box sx={{ color: option.status && "orange" }}>
                                    {option.label}
                                </Box>
                            </Box>
                        )}
                        onChange={(value, newValue) => {
                            if (newValue != null) {
                                setAccount_id(newValue.value);
                                setAccountValue(newValue);
                            } else {
                                setAccount_id({ label: "", value: "" });
                                setAccount_id();
                            }
                        }}
                        renderInput={(params) => (
                            <TextField required {...params} label="Account" />
                        )}
                    />
                    <textarea
                        required
                        onChange={(e) => {
                            setText(e.target.value.replace(/[\n\\\t\&]/, ""));
                        }}
                        value={text}
                        id="text"
                        name="text"
                        placeholder="text"
                        style={{
                            height: "100px",
                            maxWidth: "100%",
                            minWidth: "100%",
                            maxHeight: "70%",
                        }}
                    />
                    <Box>
                        {photo != "null" ? (
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                width={"100%"}
                            >
                                <Box width={"max-content"}>
                                    {photo.split("_").slice(-1)[0]}
                                </Box>
                                <IconButton
                                    onClick={() => {
                                        setPhoto("null");
                                        setEditPhoto(true);
                                    }}
                                >
                                    <Close color="error" />
                                </IconButton>
                            </Box>
                        ) : (
                            <input
                                onInput={ssPhoto}
                                id="photo"
                                name="photo"
                                type="file"
                                placeholder="photo"
                                onChange={(e) => validate(e)}
                            />
                        )}
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookLike} style={{ width: "50px" }} />
                            <input
                                value={like}
                                onInput={(e) => setLike(e.target.value)}
                                max={maxLike - love - core - wow}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookLove} style={{ width: "50px" }} />
                            <input
                                value={love}
                                onInput={(e) => setLove(e.target.value)}
                                max={maxLike - like - core - wow}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookCore} style={{ width: "50px" }} />
                            <input
                                value={core}
                                onInput={(e) => setCore(e.target.value)}
                                max={maxLike - like - love - wow}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookWow} style={{ width: "50px" }} />
                            <input
                                value={wow}
                                onInput={(e) => setWow(e.target.value)}
                                max={maxLike - like - love - core}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                    </Box>
                    {!activeLike &&
                        "Максимальное количество лайков зависит от количества аккаунтов, задействованных в написании комментариев."}

                    <button type="submit">save</button>
                </Box>
            </Dialog>
        </>
    );
}
