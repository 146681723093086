import {
    Archive,
    Delete,
    Done,
    Favorite,
    HourglassBottom,
    Pause,
    Subject,
    Warning,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";

export const botStatusColor = {
    0: "blueviolet",
    1: "#d7d042",
    2: "green",
    3: "red",
    4: "#9a9a1f",
};

const style = {
    accountMain: (status) => {
        return {
            width: "150px",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 3,
            border: "1px solid",
            borderColor: status ? (status != 3 ? "#e65100" : "red") : "green",
            color: status ? (status != 3 ? "#e65100" : "red") : "green",
            padding: "3px",
        };
    },
    proxyMain: (st) => {
        return {
            width: "100px",
            display: "flex",
            borderRadius: 3,
            border: "1px solid",
            borderColor: st ? (st != 3 ? "#e65100" : "red") : "green",
            color: st ? (st != 3 ? "#e65100" : "red") : "green",
            padding: "3px",
        };
    },
    botMain: (st) => {
        return {
            width: "100px",
            display: "flex",
            borderRadius: 3,
            border: "1px solid",
            borderColor: botStatusColor[st],
            color: botStatusColor[st],
            padding: "3px",
        };
    },
};

const active = (
    <>
        <Done fontSize="small" />
        Active
    </>
);
const wait = (
    <>
        <HourglassBottom fontSize="small" />
        Waiting...
    </>
);
const work = (
    <>
        <Subject fontSize="small" />
        Work
    </>
);
const like = (
    <>
        <Favorite fontSize="small" />
        Like
    </>
);
const done = (
    <>
        <Done fontSize="small" />
        Done
    </>
);

const logout = (
    <>
        <Warning fontSize="small" />
        logout
    </>
);
const checkpoint = (
    <>
        <Warning fontSize="small" />
        Сheckpoint
    </>
);
const archive = (
    <>
        <Archive fontSize="small" />
        Archive
    </>
);
const error = (
    <>
        <Warning fontSize="small" />
        Error
    </>
);
const del = (
    <>
        <Delete fontSize="small" />
        Delete
    </>
);
const pause = (
    <>
        <Pause fontSize="small" />
        Pause
    </>
);
export default {
    AccountStatus: (status, arr = false, last_update) => {
        if (arr) {
            return [
                {
                    label: <Box sx={style.accountMain(0)}>{active}</Box>,
                    value: 0,
                },
                // {
                //     label: <Box sx={style.accountMain(1)}>{logout}</Box>,
                //     value: 1,
                // },
                {
                    label: <Box sx={style.accountMain(2)}>{checkpoint}</Box>,
                    value: 1,
                },
                {
                    label: <Box sx={style.accountMain(2)}>{checkpoint}</Box>,
                    value: 2,
                },
                {
                    label: <Box sx={style.accountMain(3)}>{archive}</Box>,
                    value: 3,
                },
            ];
        } else {
            return (
                <Box sx={style.accountMain(status)}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {status == 0 && active}
                        {/* {status == 1 && logout} */}
                        {status == 1 && checkpoint}
                        {status == 2 && checkpoint}
                        {status == 3 && archive}
                        {status == 4 && del}
                    </Box>
                    <Box>{last_update}</Box>
                </Box>
            );
        }
    },

    ProxyStatus: (status, arr = false) => {
        if (arr) {
            return [
                {
                    label: <Box sx={style.proxyMain(0)}>{active}</Box>,
                    value: 0,
                },
                {
                    label: <Box sx={style.proxyMain(1)}>{error}</Box>,
                    value: 1,
                },
            ];
        } else {
            return (
                <Box sx={style.proxyMain(status)}>
                    {status == 0 && active}
                    {status == 1 && error}
                </Box>
            );
        }
    },

    BotStatus: (status, arr = false) => (
        <Box sx={style.botMain(status)}>
            {status === 0 && wait}
            {status === 1 && work}
            {status === 2 && done}
            {status === 3 && error}
            {status === 4 && pause}
        </Box>
    ),

    CommentStatus: (status, arr = false) => {
        if (arr) {
            return [
                {
                    label: <Box sx={style.proxyMain(0)}>{active}</Box>,
                    value: 0,
                },
                {
                    label: <Box sx={style.proxyMain(1)}>{error}</Box>,
                    value: 1,
                },
            ];
        } else {
            return (
                <Box sx={style.proxyMain(status)}>
                    {status == 0 && active}
                    {status == 1 && error}
                </Box>
            );
        }
    },
};
