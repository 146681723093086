import $api from "../api/api";

export default {
    CreateUserTeam: async (user) => {
        return await $api.post("/user", user);
    },
    getAllUsersTeam: async () => {
        return await $api.get("/user");
    },
    updateUser: async (user) => {
        return await $api.put("/user", user);
    },
    deleteUserTeam: async (id) => {
        return await $api.delete("/user", { data: { user_id: id } });
    },
    updateTeam: async (user) => {
        return await $api.put("/user", user);
    },
    deleteTelegram: async () => {
        return await $api.delete("/telegram");
    },
};
