import axios from 'axios'
import AuthService from '../services/AuthService'

const host = window.location.hostname
export let API_URL = `${window.location.protocol}//${host}:5000/api`
const $api = axios.create({
	headers: {
		'Access-Control-Allow-Origin': '*',
	},
	mode: 'cors',
	withCredentials: true,
	baseURL: API_URL,
})

$api.interceptors.request.use(config => {
	config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
	return config
})

$api.interceptors.response.use(
	config => {
		return config
	},
	async error => {
		const originalRequest = error.config
		if (error.message == 'Network Error') {
			const er = {
				response: {
					data: {
						message: 'Сервер не доступен',
					},
				},
			}
			throw er
		}
		const _isRetry = localStorage.getItem('_isRetry')

		if (
			error.response.status == 401 &&
			(_isRetry === 'false' || _isRetry === null)
		) {
			localStorage.setItem('_isRetry', true)
			try {
				const ref = await AuthService.checkAuth()
				localStorage.setItem('_isRetry', false)
				localStorage.setItem('token', ref.data.accessToken)
				return $api.request(originalRequest)
			} catch (error) {
				console.log(error)
			}
		}
		localStorage.setItem('_isRetry', false)
		throw error
	}
)

export default $api
