import AccountsCreate from "../components/Accounts/AccountsCreate/AccountsCreate";
import AccountsEdit from "../components/Accounts/AccountsEdit/AccountsEdit";
import AccountsTable from "../components/Accounts/AccountsTable/AccountsTable";
import Accounts from "../pages/Accounts";

export default {
    path: "/accounts",
    element: <Accounts />,
    children: [
        {
            index: true,
            element: <AccountsTable />,
        },
        {
            path: "create",
            element: <AccountsCreate />,
        },
        {
            path: "edit/:id",
            element: <AccountsEdit />,
        },
    ],
};
