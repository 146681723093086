import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React from "react";

export default function СonsentPopUp({
    open,
    close,
    title,
    message,
    success,
    error,
}) {
    return (
        <Dialog open={open} onClose={close}>
            <DialogTitle children={title} />
            <DialogContent>
                <DialogContentText children={message} />
            </DialogContent>
            <DialogActions>
                <Button onClick={success} color="success" variant="contained">
                    да
                </Button>
                <Button onClick={error} color="error" variant="contained">
                    нет
                </Button>
            </DialogActions>
        </Dialog>
    );
}
