import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/UserService";

export const getAllUsersTeam = createAsyncThunk(
    "user/getAllUsersTeam",
    async (action, { rejectWithValue }) => {
        try {
            const response = await UserService.getAllUsersTeam();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const CreateUserTeam = createAsyncThunk(
    "user/CreateUserTeam",
    async (action, { rejectWithValue }) => {
        try {
            const response = await UserService.CreateUserTeam(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const updateTeam = createAsyncThunk(
    "user/updateTeam",
    async (action, { rejectWithValue }) => {
        try {
            const response = await UserService.updateTeam(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteUser = createAsyncThunk(
    "user/deleteUser",
    async (action, { rejectWithValue }) => {
        try {
            const response = await UserService.deleteUserTeam(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState: {
        teamUsers: [],
        louding: false,
        error: "",
        user: {},
    },
    reducers: {},
    extraReducers: {
        [getAllUsersTeam.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [getAllUsersTeam.fulfilled]: (state, action) => {
            state.louding = false;
            state.teamUsers = action.payload;
        },
        [getAllUsersTeam.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [CreateUserTeam.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [CreateUserTeam.fulfilled]: (state, action) => {
            state.louding = false;
            state.teamUsers = action.payload;
            const id = action.payload[action.payload.length - 1].id;
            window.location = `/teamsprofile/edit/${id}`;
        },
        [CreateUserTeam.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [updateTeam.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateTeam.fulfilled]: (state, action) => {
            state.louding = false;
            state.teamUsers = action.payload;
            window.location = "/teamsprofile";
        },
        [updateTeam.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [deleteUser.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.louding = false;
            state.teamUsers = action.payload;
        },
        [deleteUser.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
    },
});

export default userSlice.reducer;
