import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getAllUsersTeam } from "../../../store/userSlice";
import { useNavigate } from "react-router-dom";

function TeamProfileTable() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Iuser = useSelector((state) => state.isAuth.user);
    const user = useSelector((state) => state.user.teamUsers);
    useEffect(() => {
        if (Iuser.roles === "Admin") {
            dispatch(getAllUsersTeam());
        }
    }, []);

    const [popOpen, setPopOpen] = useState(false);
    const [deleteID, setDeleteID] = useState();
    const [name, setName] = useState();

    const popClose = () => {
        setPopOpen(false);
    };
    return (
        <>
            <Button
                variant="contained"
                color="success"
                onClick={() => {
                    navigate("create");
                }}
            >
                Create New User
            </Button>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user.map((row, x) => (
                            <TableRow key={x}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.roles}</TableCell>
                                <TableCell align="right">
                                    <Button
                                        variant="contained"
                                        color="info"
                                        onClick={() => {
                                            navigate(`./edit/${row.id}`);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        onClick={() => {
                                            setPopOpen(true);
                                            setDeleteID(row.id);
                                            setName(row.name);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={popOpen} onClose={popClose}>
                <DialogTitle>Удалить Пользователя</DialogTitle>
                <DialogContent>
                    <DialogContentText>{name}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            popClose();
                            dispatch(deleteUser(deleteID));
                        }}
                        color="error"
                        variant="contained"
                    >
                        да
                    </Button>
                    <Button
                        onClick={popClose}
                        color="success"
                        variant="contained"
                    >
                        нет
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TeamProfileTable;
