import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import louding from "../../images/louding.gif";

export default function Loading() {
    const isAuth = useSelector((state) => state.isAuth.louding);
    const proxy = useSelector((state) => state.proxy.louding);
    const accounts = useSelector((state) => state.accounts.louding);
    const unicode = useSelector((state) => state.unicode.louding);
    const comments = useSelector((state) => state.comments.louding);
    const bot = useSelector((state) => state.bot.louding);
    const user = useSelector((state) => state.user.louding);
    const autocost = useSelector((state) => state.autocost.louding);

    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        if (
            isAuth ||
            proxy ||
            accounts ||
            unicode ||
            comments ||
            user ||
            bot ||
            autocost
        ) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isAuth, proxy, accounts, unicode, comments, user, bot, autocost]);
    return (
        <div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
