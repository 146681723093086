import React, { useEffect, useState } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./store/authSlice";
import Copyright from "./components/Copyright/Copyright";
import Headers from "./components/Headers/Headers";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SiteNavigate from "./components/Navigate/SiteNavigate";
import Loading from "./components/Loading/Loading";
import ErrorPopUp from "./components/PopUp/ErrorPopUp";
import SetingsNavigate from "./components/Navigate/SetingsNavigate";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

export default function App() {
    const theme = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [navSiteOpen, setNavSiteOpen] = useState(false);
    const [navSetingOpen, setNavSetingOpen] = useState(false);
    const auth = useSelector((state) => state.isAuth.isAuth);
    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(checkAuth());
        } else {
            setTimeout(() => {
                navigate("/login");
            }, 0);
        }
    }, []);
    useEffect(() => {
        if (location.pathname == "/") {
            navigate("/bot");
        }
    }, [location]);

    const handleDrawerNavigateClose = () => {
        setNavSiteOpen(false);
    };
    const handleDrawerNavigateOpen = () => {
        setNavSiteOpen(true);
    };
    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Headers
                    navSiteOpen={navSiteOpen}
                    handleDrawerNavigateOpen={handleDrawerNavigateOpen}
                    setNavSetingOpen={setNavSetingOpen}
                />
                <SiteNavigate
                    handleDrawerNavigateClose={handleDrawerNavigateClose}
                    navSiteOpen={navSiteOpen}
                />
                <SetingsNavigate
                    open={navSetingOpen}
                    setOpen={setNavSetingOpen}
                />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    {auth && <Outlet />}
                    {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                    <Loading />
                    <ErrorPopUp />
                </Box>
            </Box>
        </>
    );
}
