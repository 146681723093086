import $api from "../api/api";

export default {
    getProxy: async () => {
        return $api.get("/proxy");
    },
    createProxy: async ({ protocol, login, password, ip, port, name }) => {
        return $api.post("/proxy", {
            protocol,
            login,
            password,
            ip,
            port,
            name,
        });
    },
    updateProxy: async (proxy) => {
        return $api.put("/proxy", proxy);
    },
    deleteProxy: async (id) => {
        return $api.delete("/proxy", { data: { proxy_id: id } });
    },
};
