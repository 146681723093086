import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material/";
import { render } from "react-dom";

export default function ErrorPopUp() {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState("");
    const error_isAuth = useSelector((state) => state.isAuth.error);
    const error_accounts = useSelector((state) => state.accounts.error);
    const error_comments = useSelector((state) => state.comments.error);
    const error_proxy = useSelector((state) => state.proxy.error);
    const error_team = useSelector((state) => state.user.error);
    const error_bot = useSelector((state) => state.bot.error);
    const error_autocost = useSelector((state) => state.autocost.error);

    useEffect(() => {
        setText("");
        switch (true) {
            case error_isAuth.length > 5:
                setText(`${error_isAuth}`);
                setOpen(true);
                break;
            case error_accounts.length > 5:
                setText(`${error_accounts}`);
                setOpen(true);
                break;
            case error_comments.length > 5:
                setText(`${error_comments}`);
                setOpen(true);
                break;
            case error_proxy.length > 5:
                setText(`${error_proxy}`);
                setOpen(true);
                break;
            case error_team.length > 5:
                setText(`${error_team}`);
                setOpen(true);
                break;
            case error_bot.length > 1:
                setText(`${error_bot}`);
                setOpen(true);
                break;
            case error_autocost.length > 1:
                setText(`${error_autocost}`);
                setOpen(true);
                break;
        }
    }, [
        error_isAuth,
        error_accounts,
        error_comments,
        error_proxy,
        error_team,
        error_bot,
        error_autocost,
    ]);
    return (
        <Snackbar
            open={open}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={6000}
            onClose={() => setOpen(false)}
            message="Note archived"
        >
            <Alert severity="error" variant="filled">
                {text}
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setOpen(false)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </Alert>
        </Snackbar>
    );
}
