import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { getProxy } from '../store/proxySlice'
import { getAccounts } from '../store/accountsSlice'
import { getAllGroups } from '../store/groupsSlike'

export default function Accounts() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getProxy())
		dispatch(getAccounts())
		dispatch(getAllGroups())
	}, [])
	return (
		<>
			<Outlet />
		</>
	)
}
