import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountsaddError, postAccounts } from '../../../store/accountsSlice.js'
import { getProxy } from '../../../store/proxySlice.js'
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControlLabel,
	MenuItem,
	TextField,
	Typography,
} from '@mui/material'
export default function AccountsCreate() {
	const dispatch = useDispatch()
	const [value, setValue] = useState([])
	const error = useSelector(state => state.accounts.error)
	const proxy = useSelector(state => state.proxy.proxy)
	const unicodeSring = useSelector(state => state.unicode.unicode)
	const groups = useSelector(state => state.groups.groups)
	const unicode = JSON.parse(unicodeSring)

	const [proxyId, setProxyId] = useState('')
	const [status, setStatus] = useState('')
	const [name, setName] = useState('')
	const [gender, setGender] = useState('')
	const [coockie, setCoockie] = useState('')
	const [login, setLogin] = useState('')
	const [pasword, setPasword] = useState('')
	const [userAgent, setUserAgent] = useState('')
	const [auto_login, setAuto_login] = useState(false)
	const [groups_id, setGroups_id] = useState(-1)

	const handleSubmit = event => {
		event.preventDefault()
		try {
			const data = new FormData(event.currentTarget)
			const acounts = {
				name: name,
				status: +status,
				coockie: JSON.stringify(JSON.parse(coockie)),
				proxy_id: proxyId,
				login: login,
				pasword: pasword,
				unicode: value.map(i => i.value).join(','),
				gender: gender,
				userAgent: userAgent,
				autoLogin: auto_login,
				access_groups_id: groups_id,
			}
			dispatch(postAccounts(acounts))
		} catch (e) {
			dispatch(AccountsaddError('ощибка симантики'))
			console.log(e)
		}
	}

	return (
		<>
			<Box
				onSubmit={handleSubmit}
				component={'form'}
				display={'flex'}
				gap={2}
				flexDirection={'column'}
			>
				<TextField
					value={name}
					onInput={e => setName(e.target.value)}
					required
					label='Name'
				/>
				<TextField
					error={+status}
					label='Status'
					select
					required
					value={+status}
					onChange={e => setStatus(+e.target.value)}
				>
					<MenuItem value={0}>Active</MenuItem>
					<MenuItem value={1}>logout</MenuItem>
					<MenuItem value={2}>Сheckpoint</MenuItem>
					<MenuItem value={3}>Archive</MenuItem>
				</TextField>
				<TextField
					label='Gender'
					select
					required
					value={gender}
					onChange={e => setGender(e.target.value)}
				>
					<MenuItem value={'M'}>Men</MenuItem>
					<MenuItem value={'W'}>Women</MenuItem>
				</TextField>
				<Autocomplete
					multiple
					id='fixed-tags-demo'
					value={value}
					onChange={(event, newValue) => {
						setValue([...newValue])
					}}
					options={unicode}
					getOptionLabel={option => option.label}
					renderTags={(tagValue, getTagProps) =>
						tagValue.map((option, index) => (
							<Chip label={option.label} {...getTagProps({ index })} />
						))
					}
					renderInput={params => (
						<TextField {...params} label='Страна' placeholder='unicode' />
					)}
				/>
				<Divider sx={{ m: 2 }} />
				<TextField
					label='Proxy'
					select
					required
					value={proxyId}
					onChange={e => setProxyId(e.target.value)}
				>
					{proxy.map(i => (
						<MenuItem key={i.name} value={i.id}>
							{i.name}
						</MenuItem>
					))}
				</TextField>
				<TextField
					value={coockie}
					onInput={e => setCoockie(e.target.value)}
					required
					label='Cookie'
				/>
				<TextField
					value={login}
					onInput={e => setLogin(e.target.value)}
					required
					label='login'
				/>
				<TextField
					value={pasword}
					onInput={e => setPasword(e.target.value)}
					required
					label='Pasword'
				/>
				<TextField
					value={userAgent}
					onInput={e => setUserAgent(e.target.value)}
					label='User Agent'
				/>
				<FormControlLabel
					value='AutoLogin'
					control={
						<Checkbox
							onInput={e => {
								setAuto_login(!e.target.checked)
							}}
							checked={auto_login}
						/>
					}
					label='AutoLogin'
					labelPlacement='end'
				/>
				<Divider />
				<TextField
					label='Access Groups'
					select
					required
					value={groups_id}
					onChange={e => setGroups_id(e.target.value)}
				>
					<MenuItem value={-1}>ALL</MenuItem>
					{groups.map(i => (
						<MenuItem key={i.name} value={i.id}>
							{i.name}
						</MenuItem>
					))}
				</TextField>
				<Button variant='contained' type='submit'>
					Save
				</Button>
			</Box>
		</>
	)
}
