import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import {
	Comment,
	IntegrationInstructions,
	Language,
	SmartToy,
	StackedBarChart,
	SupervisorAccount,
} from '@mui/icons-material'

import setapBotImg from '../../images/setapBot.jpg'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const drawerWidth = 240

const openedMixin = theme => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
})

const closedMixin = theme => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
})

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: prop => prop !== 'navSiteOpen',
})(({ theme, navSiteOpen }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(navSiteOpen && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!navSiteOpen && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}))
const SetapBotIcon = () => (
	<>
		<img
			style={{ width: '30px', borderRadius: '50%' }}
			src={setapBotImg}
			alt='setapBot'
		/>
	</>
)

const ArrComentBot = [
	{
		text: 'Bot',
		icon: <SmartToy />,
		url: '/bot',
		roles: ['User', 'Admin', 'Group Admin'],
	},
	{
		text: 'Comments',
		icon: <Comment />,
		url: '/comments',
		roles: ['User', 'Admin', 'Group Admin'],
	},
	{
		text: 'Accounts',
		icon: <SupervisorAccount />,
		url: '/accounts',
		roles: ['User', 'Admin', 'Account Manager', 'Group Admin'],
	},
	{
		text: 'Proxy',
		icon: <Language />,
		url: '/proxy',
		roles: ['all'],
	},
	{
		text: 'Statistics',
		icon: <StackedBarChart />,
		url: '/statistics',
		roles: ['Admin'],
	},
]
const ArrAutoCost = [
	{
		text: 'Auto Cost',
		icon: <IntegrationInstructions />,
		url: '/autocost',
		roles: ['Admin'],
	},
	{
		text: 'SetapBot',
		icon: <SetapBotIcon />,
		url: '/setapbot',
		roles: ['Admin'],
	},
]
export default function SiteNavigate({
	handleDrawerNavigateClose,
	navSiteOpen,
}) {
	const theme = useTheme()
	const navigate = useNavigate()

	const roles = useSelector(state => state.isAuth.user.roles)

	const [ComentBot, setComentBot] = useState([])
	const [AutoCost, setAutoCost] = useState([])

	React.useEffect(() => {
		let comentBot = []
		let autoCost = []
		if (roles === undefined) return
		for (let i of ArrComentBot) {
			if (i.roles.includes('all')) {
				comentBot.push(i)
				continue
			}
			if (i.roles.includes(roles)) {
				comentBot.push(i)
			}
		}
		for (let i of ArrAutoCost) {
			if (i.roles.includes('all')) {
				autoCost.push(i)
				continue
			}
			if (i.roles.includes(roles)) {
				autoCost.push(i)
			}
		}
		setComentBot(comentBot)
		setAutoCost(autoCost)
	}, [roles])

	return (
		<>
			<Drawer variant='permanent' navSiteOpen={navSiteOpen}>
				<DrawerHeader>
					<IconButton onClick={handleDrawerNavigateClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					{ComentBot.map((i, index) => (
						<ListItem key={i.text} disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: navSiteOpen ? 'initial' : 'center',
									px: 2.5,
								}}
								onClick={() => navigate(i.url)}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: navSiteOpen ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									{i.icon}
								</ListItemIcon>
								<ListItemText
									primary={i.text}
									sx={{ opacity: navSiteOpen ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
				<Divider />
				<List>
					{AutoCost.map((i, index) => (
						<ListItem key={i.text} disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: navSiteOpen ? 'initial' : 'center',
									px: 2.5,
								}}
								onClick={() => navigate(i.url)}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: navSiteOpen ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									{i.icon}
								</ListItemIcon>
								<ListItemText
									primary={i.text}
									sx={{ opacity: navSiteOpen ? 1 : 0 }}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
		</>
	)
}
