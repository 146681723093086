import $api from "../api/api";

export default {
    getComments: async () => {
        return $api.get("/comment");
    },
    getComments_massage: async (commentss) => {
        return $api.post("/comment/massage", commentss);
    },
    createComments: async (commentss) => {
        return $api.post("/comment", commentss);
    },
    updateComments: async (commentss) => {
        return $api.put("/comment", commentss);
    },
    deleteComments: async (id) => {
        return $api.delete("/comment", { data: { comment_id: id } });
    },
    copyComments: async (commentss) => {
        return $api.post("/commentcopy", commentss);
    },
};
// { name, photo, unicode, text }
