import { Autocomplete, Box, Dialog, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 } from "uuid";

import FacebookLike from "../../../images/Facebook_Like.png";
import FacebookLove from "../../../images/Facebook_Love.svg";
import FacebookCore from "../../../images/Facebook_Core.png";
import FacebookWow from "../../../images/Facebook_Wow.svg";

export default function CommentPopUpCreate({
    comen,
    setComen,
    open,
    setOpen,
    variants,
    ids,
}) {
    const accounts = useSelector((state) => state.accounts.accounts);
    const [account, setAccount] = useState([]);

    const [like, setLike] = useState(0);
    const [love, setLove] = useState(0);
    const [core, setCore] = useState(0);
    const [wow, setWow] = useState(0);
    const [maxLike, setMaxLike] = useState(0);
    const [activeLike, setActiveLike] = useState(true);
    useEffect(() => {
        let unick_acount = [];
        for (let i of comen) {
            if (!unick_acount.includes(i.fb_account_id)) {
                unick_acount.push(i.fb_account_id);
            }
        }
        const sum = +like + +love + +core + +wow;
        setMaxLike(unick_acount.length);
        if (unick_acount.length <= sum) {
            setActiveLike(false);
        } else {
            setActiveLike(true);
        }
    }, [like, love, core, wow, comen]);

    useEffect(() => {
        let mas = [];
        for (let i of accounts) {
            mas.push({
                label: `${i.name} - ${i.gender} - ${i.unicode}`,
                value: i.id,
                status: i.status,
            });
        }
        setAccount(mas);
    }, [accounts]);
    const [account_id, setAccount_id] = useState();
    const [text, setText] = useState();
    const addComen = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const come = {
            id: Math.random(),
            text: text
                .split('"')
                .join("`")
                .split("'")
                .join("`")
                .split("\n")
                .join(" ")
                .split("\\")
                .join("")
                .split("\t")
                .join(" ")
                .split("&")
                .join(" ")
                .replace(/[\n\\\t\&]/, ""),
            photo: data.get("photo").name ? data.get("photo") : "null",
            photo_id: data.get("photo").name
                ? v4().split("-")[0] +
                  "_" +
                  data.get("photo").name.split(" ").join("")
                : "null",
            variant: variants,
            fb_account_id: account_id,
            like_0: +like,
            like_1: +love,
            like_2: +core,
            like_3: +wow,
        };
        if (ids != -1) {
            let res = [];
            let vas = -1;
            for (let i = 0; i < comen.length; i++) {
                if (ids == comen[i].id) {
                    res.push(comen[i]);
                    vas = 1;
                    continue;
                }
                if (comen[i].variant < variants && vas === 1) {
                    vas = 0;
                    res.push(come);
                }
                res.push(comen[i]);
            }
            if (vas === 1) {
                res.push(come);
            }
            setComen(res);
        } else {
            setComen([...comen, come]);
        }

        setOpen(false);
        setText("");
        setLike(0);
        setLove(0);
        setCore(0);
        setWow(0);
    };
    const closes = () => {
        setOpen(false);
        setText("");
        setLike(0);
        setLove(0);
        setCore(0);
        setWow(0);
    };
    function validate(elem) {
        const name = elem.target.files[0].name;
        var alphaExp = /^([a-z_A-Z\-\s0-9\.]+)+\.(png|gif|jpg|jpeg)$/;
        if (name.match(alphaExp)) {
            return true;
        } else {
            alert(
                "In the title of the photo, use English and numbers without special characters. Acceptable format: png, jpg, jpeg"
            );
            elem.target.value = "";
            return false;
        }
    }
    return (
        <>
            <Dialog onClose={closes} open={open}>
                <div style={{ textAlign: "center" }}>
                    {variants == 1 && "Коментарий"}
                    {variants == 2 && "Вопрос"}
                    {variants == 3 && "Ответ"}
                </div>
                <Box
                    id="form"
                    onSubmit={addComen}
                    component={"form"}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "300px",
                        height: "550px",
                        padding: 2,
                    }}
                >
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={account}
                        renderOption={(props, option) => (
                            <Box component="li" {...props}>
                                <Box sx={{ color: option.status && "orange" }}>
                                    {option.label}
                                </Box>
                            </Box>
                        )}
                        onChange={(value, newValue) => {
                            if (newValue != null) {
                                setAccount_id(newValue.value);
                            } else {
                                setAccount_id();
                            }
                        }}
                        renderInput={(params) => (
                            <TextField required {...params} label="Account" />
                        )}
                    />
                    <textarea
                        required
                        onChange={(e) => {
                            setText(e.target.value.replace(/[\n\\\t\&]/, ""));
                        }}
                        value={text}
                        id="text"
                        name="text"
                        placeholder="text"
                        style={{
                            height: "100px",
                            maxWidth: "100%",
                            minWidth: "100%",
                            maxHeight: "70%",
                        }}
                    />
                    <input
                        id="photo"
                        name="photo"
                        type="file"
                        placeholder="photo"
                        onChange={(e) => validate(e)}
                    />
                    <Box display="flex" justifyContent={"space-between"}>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookLike} style={{ width: "50px" }} />
                            <input
                                value={like}
                                onInput={(e) => setLike(e.target.value)}
                                max={maxLike - love - core - wow}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookLove} style={{ width: "50px" }} />
                            <input
                                value={love}
                                onInput={(e) => setLove(e.target.value)}
                                max={maxLike - like - core - wow}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookCore} style={{ width: "50px" }} />
                            <input
                                value={core}
                                onInput={(e) => setCore(e.target.value)}
                                max={maxLike - like - love - wow}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                        <label
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <img src={FacebookWow} style={{ width: "50px" }} />
                            <input
                                value={wow}
                                onInput={(e) => setWow(e.target.value)}
                                max={maxLike - like - love - core}
                                min={0}
                                style={{ width: "50px" }}
                                type="number"
                            />
                        </label>
                    </Box>
                    {!activeLike &&
                        "Максимальное количество лайков зависит от количества аккаунтов, задействованных в написании комментариев."}
                    <button type="submit">new coment</button>
                </Box>
            </Dialog>
        </>
    );
}
