import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	Autocomplete,
	Avatar,
	Badge,
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	IconButton,
	SpeedDialIcon,
	TextField,
} from '@mui/material'

import FacebookLike from '../../../images/Facebook_Like.png'
import FacebookLove from '../../../images/Facebook_Love.svg'
import FacebookCore from '../../../images/Facebook_Core.png'
import FacebookWow from '../../../images/Facebook_Wow.svg'
import { Link } from 'react-router-dom'
import StatusModel from '../../StatusModel/StatusModel'

export default function CommentScheme({
	name,
	coutry,
	comen,
	setName,
	setCoutry,
	openPopUpCreateComent,
	openPopUpEditComent,
	deleteComments,
	handleSubmit,
	visibility,
	setVisibility,
	user_id,
}) {
	const accounts = useSelector(state => state.accounts.accounts)
	const userId = useSelector(state => state.isAuth.user.id)
	const [accountValue, setAccountValue] = useState({ label: '', value: '' })
	const unicodeSring = useSelector(state => state.unicode.unicode)
	const unicode = JSON.parse(unicodeSring)
	useEffect(() => {
		for (let f of unicode) {
			if (f.value == coutry) {
				setAccountValue(f)
			}
		}
	}, [comen])
	const acountName = id => {
		for (let i of accounts) {
			if (id == i.id) {
				return `${i.name} - ${i.gender} - ${i.unicode}`
			}
		}
	}
	const acountStatus = id => {
		let result = {}
		for (let i of accounts) {
			if (id == i.id) {
				result = {
					acount: i.status,
					proxy: i.proxy_status,
					status: i.proxy_status + i.status,
				}
				return result
			}
		}
		result = {
			acount: 4,
			proxy: 1,
			status: 1,
		}
		return result
	}
	return (
		<>
			<Box
				onSubmit={handleSubmit}
				component={'form'}
				display={'flex'}
				flexDirection={'column'}
				gap={2}
			>
				<TextField
					id='name'
					name='name'
					value={name}
					onInput={e => setName(e.target.value)}
					variant='outlined'
					type='text'
					label='Name'
					required
				/>
				<Autocomplete
					id='combo-box-demo'
					value={accountValue}
					options={unicode}
					onChange={(value, newValue) => {
						if (newValue != null) {
							setAccountValue(newValue)
							setCoutry(newValue.value)
						} else {
							setAccountValue({ label: '', value: '' })
							setCoutry()
						}
					}}
					renderInput={params => <TextField {...params} label='Страна' />}
				/>
				{userId === user_id && (
					<FormControlLabel
						value='Hide'
						control={
							<Checkbox
								onInput={e => {
									setVisibility(!e.target.checked)
								}}
								checked={visibility}
							/>
						}
						label='Hide'
						labelPlacement='end'
					/>
				)}

				<Divider />

				<Box
					sx={{
						border: '1px solid #F0F2F5',
						boxShadow: '0 0 10px #F0F2F5',
						borderRadius: '25px',
						padding: 2,
						gap: 3,
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					{comen.map((i, z) => {
						const { status, proxy, acount } = acountStatus(i.fb_account_id)
						const accountName = acountName(i.fb_account_id)
						return (
							<Box
								className=''
								key={z}
								display={'flex'}
								justifyContent={'space-between'}
								gap={'15px'}
								sx={{
									marginLeft: i.variant == 1 ? 0 : i.variant == 2 ? 10 : 20,
								}}
							>
								<Box maxWidth={'500px'} display={'flex'}>
									<Avatar
										sx={{
											background: !accountName ? 'red' : status ? 'red' : '',
										}}
									>
										{accountName ? accountName.split('-')[0][5] : console.log()}
									</Avatar>
									<Box>
										<Box
											sx={{
												display: status ? 'block' : 'none',
											}}
											ml={3}
											fontWeight={600}
										>
											<Box display={'flex'} gap={3}>
												Account:
												{StatusModel.AccountStatus(acount)}
											</Box>
											<Box display={'flex'} gap={3}>
												Proxy:
												{StatusModel.ProxyStatus(proxy)}
											</Box>
										</Box>
										<Badge
											color='info'
											badgeContent={
												+i.like_0 + +i.like_1 + +i.like_2 + +i.like_3
											}
										>
											<Box
												sx={{
													width: '100%',
													padding: '8px 12px',
													borderRadius: '25px',
													background: !accountName
														? 'red'
														: status
														? 'red'
														: '#F0F2F5',
												}}
											>
												<Box
													sx={{
														color: '#050505',
														fontWeight: 600,
													}}
												>
													<Link
														style={{
															color: 'black',
														}}
														to={`/accounts/edit/${i.fb_account_id}`}
													>
														{accountName}
													</Link>
												</Box>
												<Box
													sx={{
														color: '#050505',
													}}
												>
													{i.text}
												</Box>
												<Box mt={1}>
													<Badge color='primary' badgeContent={i.like_0}>
														<img
															style={{
																width: '30px',
																display: i.like_0 ? 'flex' : 'none',
															}}
															src={FacebookLike}
															alt=''
														/>
													</Badge>
													<Badge color='primary' badgeContent={i.like_1}>
														<img
															sx={{}}
															style={{
																width: '30px',
																display: i.like_1 ? 'flex' : 'none',
															}}
															src={FacebookLove}
															alt=''
														/>
													</Badge>
													<Badge color='primary' badgeContent={i.like_2}>
														<img
															style={{
																width: '30px',
																display: i.like_2 ? 'flex' : 'none',
															}}
															src={FacebookCore}
															alt=''
														/>
													</Badge>
													<Badge color='primary' badgeContent={i.like_3}>
														<img
															style={{
																width: '30px',
																display: i.like_3 ? 'flex' : 'none',
															}}
															src={FacebookWow}
															alt=''
														/>
													</Badge>
												</Box>
											</Box>
										</Badge>
										<Box>
											{i.variant == 1 && (
												<Button onClick={() => openPopUpCreateComent(2, i.id)}>
													Ответить
												</Button>
											)}
											{i.variant == 2 && (
												<Button onClick={() => openPopUpCreateComent(3, i.id)}>
													Ответить
												</Button>
											)}
										</Box>
										{i.photo != 'null' && !i.photo?.name && (
											<img
												style={{
													maxWidth: '260px',
													maxHeight: '210px',
													borderRadius: '25px',
												}}
												src={`https://comentbot.trafficfamily.fun:5000/comentBot/comment/${i.photo}`}
											/>
										)}
										{i.photo?.name && (
											<img
												style={{
													maxWidth: '260px',
													maxHeight: '210px',
													borderRadius: '25px',
												}}
												src={URL.createObjectURL(i.photo)}
											/>
										)}
									</Box>
								</Box>
								<Box mt={5}>
									<Button
										color='info'
										onClick={() => openPopUpEditComent(i.id)}
									>
										Edit
									</Button>
									<Button
										color='error'
										onClick={() => deleteComments(i.id, i.variant)}
									>
										Delete
									</Button>
								</Box>
							</Box>
						)
					})}

					<Box>
						<IconButton
							onClick={() => openPopUpCreateComent(1, -1)}
							size='large'
							color='info'
						>
							<SpeedDialIcon />
						</IconButton>
					</Box>
				</Box>
				<br />
				<Button
					sx={{
						position: 'sticky',
						bottom: 20,
					}}
					type='submit'
					variant='contained'
					color='success'
				>
					SAVE
				</Button>
			</Box>
		</>
	)
}
