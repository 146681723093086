import { Box, Button, MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { deleteProxy } from '../../../store/proxySlice'
import { useNavigate } from 'react-router-dom'
import { Delete, Done, OpenInNew, Warning } from '@mui/icons-material'
import { GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid'
import StatusModel from '../../StatusModel/StatusModel'
import СonsentPopUp from '../../PopUp/СonsentPopUp'
import Table from '../../Table/Table'
import Cookies from 'js-cookie'

export default function ProxyTable() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const proxy = useSelector(state => state.proxy.proxy)
	const [deleteID, setDeleteID] = useState()
	const [popOpen, setPopOpen] = useState(false)

	const [rows, setRows] = useState([])
	const [StatusSort, setStatusSort] = useState('All Status')
	const [arrStatusSort, setArrStatusSort] = useState([
		'All Status',
		'Error',
		'Active',
	])

	const popClose = () => {
		setPopOpen(false)
	}

	useEffect(() => {
		let result = []
		for (let i of proxy) {
			if (StatusSort !== 'All Status') {
				if (StatusSort === 'Error' && i.status === 0) continue
				if (StatusSort === 'Active' && i.status === 1) continue
			}
			result.push(i)
		}
		setRows(result)
	}, [StatusSort, proxy])

	const handleDeleteClick = id => () => {
		setDeleteID(id)
		setPopOpen(true)
	}

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			type: 'numder',
			editable: false,
			flex: 0.5,
		},
		{
			field: 'name',
			headerName: 'Name',
			type: 'string',
			flex: 2,
			editable: false,
			resizable: false,
		},
		{
			field: 'protocol',
			headerName: 'Protocol',
			type: 'string',
			editable: false,
			flex: 1,
		},
		{
			field: 'login',
			headerName: 'Login',
			type: 'string',
			editable: false,
			flex: 1,
		},
		{
			field: 'pasword',
			headerName: 'Pasword',
			type: 'string',
			editable: false,
			flex: 1,
		},
		{
			field: 'ip',
			headerName: 'IP',
			type: 'string',
			editable: false,
			flex: 2,
		},
		{
			field: 'port',
			headerName: 'Port',
			type: 'numder',
			editable: false,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Status',
			type: 'singleSelect',
			editable: false,
			width: 100,
			renderCell: params => {
				const st = params.row.status
				return StatusModel.ProxyStatus(st)
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			resizable: false,
			flex: 1,
			cellClassName: 'actions',
			getActions: params => {
				const id = params.row.id
				return [
					<GridActionsCellItem
						icon={<OpenInNew />}
						label='Edit'
						className='textPrimary'
						onClick={() => navigate(`edit/${id}`)}
						color='inherit'
					/>,
					<GridActionsCellItem
						icon={<Delete />}
						label='Delete'
						onClick={handleDeleteClick(id)}
						color='inherit'
					/>,
				]
			},
		},
	]

	function QuickSearchToolbar() {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around',
					gap: 2,
					p: 0.5,
					// pb: 0,
					background: '#eee',
				}}
			>
				<GridToolbarQuickFilter
					variant='outlined'
					sx={{
						width: '50%',
						background: '#fff',
						borderRadius: 1,
						pb: 0,
					}}
				/>
				<TextField
					sx={{
						width: '25%',
						background: '#fff',
						borderRadius: 1,
						pb: 0,
					}}
					onChange={e => setStatusSort(e.target.value)}
					value={StatusSort}
					label='Status'
					select
				>
					{arrStatusSort.map(i => (
						<MenuItem key={i} value={i}>
							{i}
						</MenuItem>
					))}
				</TextField>
			</Box>
		)
	}
	return (
		<>
			<Button
				onClick={() => navigate('create')}
				color='success'
				variant='contained'
			>
				Добавить
			</Button>
			<Table
				rows={rows}
				columns={columns}
				slots={{ toolbar: QuickSearchToolbar }}
			/>
			<СonsentPopUp
				open={popOpen}
				close={popClose}
				title={'Удалить прокси ?'}
				message={''}
				success={() => {
					popClose()
					dispatch(deleteProxy(deleteID))
				}}
				error={popClose}
			/>
		</>
	)
}
