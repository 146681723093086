import TeamProfileCreate from "../components/TeamProfile/TeamProfileCreate/TeamProfileCreate";
import TeamProfileEdit from "../components/TeamProfile/TeamProfileEdit/TeamProfileEdit";
import TeamProfileTable from "../components/TeamProfile/TeamProfileTable/TeamProfileTable";
import Messages from "../pages/Messages";
import Settings from "../pages/Settings";
import TeamsProfile from "../pages/TeamsProfile";

export const messages = {
    path: "/messages",
    element: <Messages />,
};

export const settings = {
    path: "/settings",
    element: <Settings />,
};
export const teamsprofile = {
    path: "/teamsprofile",
    element: <TeamsProfile />,
    children: [
        {
            index: true,
            element: <TeamProfileTable />,
        },
        {
            path: "create",
            element: <TeamProfileCreate />,
        },
        {
            path: "edit/:id",
            element: <TeamProfileEdit />,
        },
    ],
};
