import { createBrowserRouter } from "react-router-dom";
import App from "../App";

import Auth from "../pages/Auth";
import Error from "../pages/Error";
import botRouter from "./bot-router";
import proxyRouter from "./proxy-router";
import accountsRouter from "./accounts-router";
import commentsRouter from "./comments-router";
import { settings, messages, teamsprofile } from "./settings-router";
import autocostRouter from "./autocost-router";
import { SetapBotRouter } from "./setapBot-router";
import { StatisticsRouter } from "./statistics-router";

export const router = createBrowserRouter([
    {
        path: "/login",
        element: <Auth />,
        errorElement: <Error />,
    },
    {
        path: "/",
        element: <App />,
        errorElement: <Error />,
        children: [
            botRouter,
            proxyRouter,
            accountsRouter,
            commentsRouter,
            settings,
            messages,
            teamsprofile,
            autocostRouter,
            SetapBotRouter,
            StatisticsRouter,
        ],
    },
]);
