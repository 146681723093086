import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { getProxy } from "../store/proxySlice";
import { useDispatch } from "react-redux";

export default function Proxy() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProxy());
    }, []);
    return <Outlet />;
}
