import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AutoCostService from "../services/AutoCostService";

export const AutoCostGetCookieFbTool = createAsyncThunk(
    "autocost/AutoCostGetCookieFbTool",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AutoCostService.getCookie();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const AutoCostPostCookieFbTool = createAsyncThunk(
    "autocost/AutoCostPostCookieFbTool",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AutoCostService.poostCookie(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const AutoCostGetTask = createAsyncThunk(
    "autocost/AutoCostGetTask",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AutoCostService.getPostFbtool();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const userSlice = createSlice({
    name: "autocost",
    initialState: {
        cookie: {
            id: 0,
            cookie: "",
            status: "",
        },
        fbtool: { accounts: [], post: [] },
        zeus: {},
        task: [],
        possible_task: [],
        louding: false,
        error: "",
    },
    reducers: {},
    extraReducers: {
        [AutoCostGetCookieFbTool.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [AutoCostGetCookieFbTool.fulfilled]: (state, action) => {
            state.louding = false;
            state.cookie = action.payload;
        },
        [AutoCostGetCookieFbTool.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // ---
        [AutoCostPostCookieFbTool.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [AutoCostPostCookieFbTool.fulfilled]: (state, action) => {
            state.louding = false;
            state.cookie = action.payload;
        },
        [AutoCostPostCookieFbTool.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // ---
        [AutoCostGetTask.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [AutoCostGetTask.fulfilled]: (state, action) => {
            state.louding = false;
            state.fbtool = action.payload;
        },
        [AutoCostGetTask.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
    },
});

export default userSlice.reducer;
