import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AutoCostGetCookieFbTool } from "../store/autoCostSlice";
import { useDispatch } from "react-redux";

export default function AutoCost() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(AutoCostGetCookieFbTool());
    }, []);
    return (
        <>
            <Outlet />
        </>
    );
}
