import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

const VisibilityDefaultModel = {
	bot: {
		coolieName: 'VisibilityParamBot',
		defaultMode: {},
	},
	accounts: {
		coolieName: 'VisibilityParamAccount',
		defaultMode: {
			id: false,
			unicode: false,
			dat: false,
			user_agent: false,
			auto_login: false,
			proxy_name: false,
			last_update: false,
		},
	},
	proxy: {
		coolieName: 'VisibilityParamProxy',
		defaultMode: {
			id: false,
			protocol: false,
			login: false,
			ip: false,
			pasword: false,
			port: false,
			protocol: false,
		},
	},
	comments: {
		coolieName: 'VisibilityParamComment',
		defaultMode: {
			users: true,
		},
	},
	InfoAcountPopUp: {
		coolieName: 'VisibilityParamInfoAcountPopUp',
		defaultMode: {
			id: false,
			usAgent: false,
			autoLogin: false,
		},
	},
}
const ColumnWidthChangeMode = {
	bot: {
		coolieName: 'ColumnWidthChangeBot',
	},
	accounts: {
		coolieName: 'ColumnWidthChangeAccount',
	},
	comments: {
		coolieName: 'ColumnWidthChangeComments',
	},
	proxy: {
		coolieName: 'ColumnWidthChangeProxy',
	},
	InfoAcountPopUp: {
		coolieName: 'VisibilityParamInfoAcountPopUp',
	},
}
export default function Table(props) {
	const location = useLocation()
	const popUp = props?.popUp
	const path =
		popUp === undefined ? location.pathname.split('/')[1].toLowerCase() : popUp
	const [VisibilityModel, setVisibilityModel] = useState({})
	const [castomColumns, setCastomColumns] = useState([])
	useEffect(() => {
		const coolieNameVmod = VisibilityDefaultModel[path]?.coolieName
		const defaultModeVmod = VisibilityDefaultModel[path]?.defaultMode

		if (coolieNameVmod === undefined && defaultModeVmod === undefined) {
			setVisibilityModel({})
			return
		}

		let param = Cookies.get(coolieNameVmod)
		if (param !== undefined) {
			param = JSON.parse(param)
			setVisibilityModel(param)
		} else {
			setVisibilityModel(defaultModeVmod)
		}
		//
	}, [location])
	useEffect(() => {
		const coolieNameHmod = ColumnWidthChangeMode[path]?.coolieName
		if (coolieNameHmod === undefined) return

		let param = Cookies.get(coolieNameHmod)
		let resultC = props.columns
		if (param !== undefined) {
			resultC = []
			param = JSON.parse(param)
			for (let i of props.columns) {
				if (param[i.field] !== undefined) {
					i.width = param[i.field]
					i.flex = null
				}
				resultC.push(i)
			}
		}
		setCastomColumns(resultC)
	}, [location])

	const handleColumnVisibilityChange = param => {
		setVisibilityModel(param)
		Cookies.set(VisibilityDefaultModel[path]?.coolieName, JSON.stringify(param))
	}
	const handleColumnWidthChange = params => {
		const field = params.colDef.field
		const width = params.width
		const coolieNameHmod = ColumnWidthChangeMode[path]?.coolieName
		let param = Cookies.get(coolieNameHmod)
		if (param !== undefined) {
			param = JSON.parse(param)
			param[field] = width
		} else {
			param = {}
			param[field] = width
		}
		Cookies.set(coolieNameHmod, JSON.stringify(param))
	}
	return (
		<div style={{ maxWidth: '100wh' }}>
			<DataGrid
				{...props}
				// columns={castomColumns}
				getRowHeight={() => 'auto'}
				editMode='row'
				onColumnWidthChange={handleColumnWidthChange}
				disableColumnFilter={true}
				columnVisibilityModel={VisibilityModel}
				onColumnVisibilityModelChange={handleColumnVisibilityChange}
				getRowClassName={params =>
					`super-app-theme--${params.row.status} super-app-theme--${
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}`
				}
			/>
		</div>
	)
}
