import React from "react";
import { useNavigate, useRouteError } from "react-router-dom";

export default function Error() {
    const error = useRouteError();
    const navigate = useNavigate();
    navigate("/bot");
    return (
        <>
            <h1>Oops!</h1>
            <p>Sorry, something went wrong</p>
            <p>{error.error.message}</p>
        </>
    );
}
