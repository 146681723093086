import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";

export const login = createAsyncThunk(
    "auth/login",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AuthService.login(
                action.email,
                action.password
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const registration = createAsyncThunk(
    "auth/registration",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AuthService.registration(
                action.email,
                action.password
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem("token");
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const checkAuth = createAsyncThunk(
    "auth/checkAuth",
    async (action, { rejectWithValue }) => {
        try {
            const response = await AuthService.checkAuth();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const updateUser = createAsyncThunk(
    "auth/updateUser",
    async (action, { rejectWithValue }) => {
        try {
            const response = await UserService.updateUser(action);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const deleteTelegram = createAsyncThunk(
    "auth/deleteTelegram",
    async (action, { rejectWithValue }) => {
        try {
            const response = await UserService.deleteTelegram();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuth: false,
        louding: false,
        error: "",
        user: {},
    },
    reducers: {},
    extraReducers: {
        [login.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [login.fulfilled]: (state, action) => {
            state.louding = false;
            state.isAuth = true;
            state.user = action.payload.user;
            localStorage.setItem("token", action.payload.accessToken);
        },
        [login.rejected]: (state, action) => {
            state.louding = false;
            state.isAuth = false;
            state.user = {};
            state.error = action.payload.message;
        },
        // --
        [registration.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [registration.fulfilled]: (state, action) => {
            state.louding = false;
            state.isAuth = true;
            state.user = action.payload.user;
            localStorage.setItem("token", action.payload.accessToken);
        },
        [registration.rejected]: (state, action) => {
            state.louding = false;
            state.isAuth = false;
            state.user = {};
            state.error = action.payload.message;
        },
        //--
        [logout.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [logout.fulfilled]: (state, action) => {
            state.louding = false;
            state.isAuth = false;
            state.user = {};
            localStorage.removeItem("token");
            window.location = "login";
        },
        [logout.rejected]: (state, action) => {
            state.louding = false;
            state.isAuth = false;
            state.user = {};
            state.error = action.payload.message;
        },
        //--
        [checkAuth.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [checkAuth.fulfilled]: (state, action) => {
            state.louding = false;
            state.isAuth = true;
            state.user = action.payload.user;
            localStorage.setItem("token", action.payload.accessToken);
        },
        [checkAuth.rejected]: (state, action) => {
            state.louding = false;
            state.isAuth = false;
            state.user = {};
            state.error = action.payload.message;
            localStorage.removeItem("token");
            window.location = "login";
        },
        // --
        [updateUser.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [updateUser.fulfilled]: (state, action) => {
            state.louding = false;
            state.user = action.payload.user;
            localStorage.setItem("token", action.payload.accessToken);
            window.location = "/bot";
        },
        [updateUser.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
        // --
        [deleteTelegram.pending]: (state, action) => {
            state.louding = true;
            state.error = "";
        },
        [deleteTelegram.fulfilled]: (state, action) => {
            state.louding = false;
            state.user = action.payload.user;
            localStorage.setItem("token", action.payload.accessToken);
        },
        [deleteTelegram.rejected]: (state, action) => {
            state.louding = false;
            state.error = action.payload.message;
        },
    },
});
export default authSlice.reducer;
