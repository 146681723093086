import AutoCostCreate from "../components/AutoCost/AutoCostCreate";
import AutoCostTable from "../components/AutoCost/AutoCostTable";
import AutoCost from "../pages/AutoCost";

export default {
    path: "/autocost",
    element: <AutoCost />,
    children: [
        {
            index: true,
            element: <AutoCostTable />,
        },
        {
            path: "create",
            element: <AutoCostCreate />,
        },
    ],
};
