import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CommentsService from '../services/CommentsService'

const editComentPending = async response => {
	let res = []

	for (let i of response.data) {
		res.push({
			...i,
			status: i.status > 0 ? 1 : 0,
		})
	}
	return res
}
export const getComments = createAsyncThunk(
	'comments/getComments',
	async (action, { rejectWithValue }) => {
		try {
			const response = await CommentsService.getComments()
			const result = await editComentPending(response)
			return result
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)
export const getComments_massage = createAsyncThunk(
	'comments/getComments_massage',
	async (action, { rejectWithValue }) => {
		try {
			const response = await CommentsService.getComments_massage(action)
			return response
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)
export const postComments = createAsyncThunk(
	'comments/postComments',
	async (action, { rejectWithValue }) => {
		try {
			const response = await CommentsService.createComments(action)
			const result = await editComentPending(response)
			return result
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)
export const deleteComments = createAsyncThunk(
	'comments/deleteComments',
	async (action, { rejectWithValue }) => {
		try {
			const response = await CommentsService.deleteComments(action)
			const result = await editComentPending(response)
			return result
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)
export const updateComments = createAsyncThunk(
	'comments/updateComments',
	async (action, { rejectWithValue }) => {
		try {
			const response = await CommentsService.updateComments(action)
			const result = await editComentPending(response)
			return result
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)
export const copyComments = createAsyncThunk(
	'comments/copyComments',
	async (action, { rejectWithValue }) => {
		try {
			const response = await CommentsService.copyComments(action)
			const result = await editComentPending(response)
			return result
		} catch (error) {
			return rejectWithValue(error.response.data)
		}
	}
)
const commentsSlice = createSlice({
	name: 'comments',
	initialState: {
		louding: false,
		comments: [],
		massage: [],
		error: '',
	},
	reducers: {},
	extraReducers: {
		[getComments.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[getComments.fulfilled]: (state, action) => {
			state.comments = action.payload
			state.louding = false
		},
		[getComments.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
		// --
		[getComments_massage.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[getComments_massage.fulfilled]: (state, action) => {
			state.massage = action.payload.data
			state.louding = false
		},
		[getComments_massage.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
		// --
		[postComments.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[postComments.fulfilled]: (state, action) => {
			state.comments = action.payload
			state.louding = false
			window.location = '/comments'
		},
		[postComments.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
		// --
		[deleteComments.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[deleteComments.fulfilled]: (state, action) => {
			state.comments = action.payload
			state.louding = false
		},
		[deleteComments.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
		// --
		[updateComments.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[updateComments.fulfilled]: (state, action) => {
			state.comments = action.payload
			state.louding = false
			window.location = '/comments'
		},
		[updateComments.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
		// --
		[copyComments.pending]: (state, action) => {
			state.louding = true
			state.error = ''
		},
		[copyComments.fulfilled]: (state, action) => {
			state.comments = action.payload
			state.louding = false
		},
		[copyComments.rejected]: (state, action) => {
			state.louding = false
			state.error = action.payload.message
		},
	},
})

export default commentsSlice.reducer
