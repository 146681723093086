import ProxyCreate from "../components/Proxy/ProxyCreate/ProxyCreate";
import ProxyEdit from "../components/Proxy/ProxyEdit/ProxyEdit";
import ProxyTable from "../components/Proxy/ProxyTable/ProxyTable";
import Proxy from "../pages/Proxy";

export default {
    path: "/proxy",
    element: <Proxy />,
    children: [
        {
            index: true,
            element: <ProxyTable />,
        },
        {
            path: "create",
            element: <ProxyCreate />,
        },
        {
            path: "edit/:id",
            element: <ProxyEdit />,
        },
    ],
};
