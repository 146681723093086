import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postBot } from '../../../store/botSlice'

export default function BotCreatePopUp({ open, setOpen }) {
	const dispatch = useDispatch()
	const comments = useSelector(state => state.comments.comments)

	const [arrGeo, setArrGeo] = useState([])
	const [geo, setGeo] = useState('')
	const [option, setOption] = useState('')
	const [link, setLink] = useState('')
	const [Errorlink, setErrorLink] = useState(false)

	useEffect(() => {
		setLink('')
		setOption('')
		setGeo('')
	}, [open])

	useEffect(() => {
		let geo = []
		for (let i of comments) {
			if (!geo.includes(i.unicode)) geo.push(i.unicode)
		}
		setArrGeo(geo)
	}, [comments, geo])
	const linkValidate = link => {
		setErrorLink(false)
		let status = false
		if (
			Boolean(
				link.match(
					/^(https:\/\/|)(www\.|)facebook\.com\/(permalink\.php\?story_fbid=|[0-9]{14}\/posts|watch\/\?|[0-9]{14,16}\/videos\/[0-9]{14,16})(\/|)/gm
				)
			)
		) {
			status = true
		}
		if (status) {
			setLink(link)
		} else {
			setErrorLink(true)
			setLink('')
		}
	}
	const handleSubmit = event => {
		event.preventDefault()
		if (!option) setErrorLink(true)
		setOpen(false)
		const Bot = {
			comments_id: option,
			link: link,
			liks: 0,
		}
		dispatch(postBot(Bot))
	}
	return (
		<Dialog
			onSubmit={handleSubmit}
			component={'form'}
			open={open}
			onClose={() => setOpen(false)}
		>
			<DialogTitle>Добавить Тз Боту</DialogTitle>
			<DialogContent sx={{ width: '300px' }}>
				<DialogContentText>
					<Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
						<TextField
							select
							label='Сountry'
							required
							onChange={e => setGeo(e.target.value)}
						>
							{arrGeo.map((geo, id) => (
								<MenuItem key={id} value={geo} children={geo} />
							))}
						</TextField>
						{geo && (
							<TextField
								id='variant'
								name='variant'
								required
								select
								label='Option'
								onChange={e => setOption(e.target.value)}
							>
								{comments.map((i, id) => {
									if (i.unicode === geo) {
										return (
											<MenuItem
												key={id}
												value={i.id}
												children={i.name}
												sx={{
													color: i.status ? 'orange' : 'green',
												}}
											/>
										)
									}
								})}
							</TextField>
						)}
						{option && (
							<TextField
								name='link'
								id='link'
								required
								type='text'
								error={Errorlink}
								value={link}
								onInput={e => linkValidate(e.target.value)}
								label='Link'
								variant='outlined'
							/>
						)}
					</Box>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					sx={{ margin: 'auto' }}
					variant='contained'
					color='success'
					type='submit'
				>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	)
}
