import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormLabel,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function NotesPopUp({ open, close, save, basText }) {
    const [text, setText] = useState("");
    useEffect(() => {
        if (basText !== undefined && basText !== null && open) {
            setText(basText);
        } else {
            setText("");
        }
    }, [open]);
    const editText = (text) => {
        const result = text
            .split('"')
            .join("`")
            .split("'")
            .join("`")
            // .split("\n")
            // .join(" ")
            .split("\\")
            .join("")
            .split("\t")
            .join(" ")
            .split("&")
            .join(" ")
            .replace(/[\\\t\&]/, "");
        setText(result);
    };

    return (
        <Dialog
            open={open}
            onClose={() => {
                setText("");
                close();
            }}
            fullWidth
        >
            <DialogContent
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <TextField
                    value={text}
                    onChange={(e) => editText(e.target.value)}
                    label="Notes"
                    minRows={6}
                    multiline
                    fullWidth
                />
            </DialogContent>
            <DialogActions
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Button
                    onClick={() => {
                        save(text);
                        close();
                    }}
                    variant="contained"
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
}
