import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getComments,
	getComments_massage,
	updateComments,
} from '../../../store/commentsSlice'
import { getAccounts } from '../../../store/accountsSlice'
import CommentPopUpCreate from '../CommentPopUpCreate/CommentPopUpCreate'
import CommentPopUpEdit from '../CommentPopUpEdit/CommentPopUpEdit'
import { useParams } from 'react-router-dom'
import CommentScheme from '../CommentScheme/CommentScheme'

export default function CommentEdit() {
	const dispatch = useDispatch()
	const { id } = useParams()
	const comments = useSelector(state => state.comments.comments)
	const massage = useSelector(state => state.comments.massage)

	useEffect(() => {
		dispatch(getAccounts())
		dispatch(getComments_massage({ coment_id: id }))
	}, [])

	useEffect(() => {
		for (let i of comments) {
			if (id == i.id) {
				setName(i.name)
				setCoutry(i.unicode)
				setUser_id(i.user_id)
				setVisibility(i.visibility)
			}
		}
		setComen(massage)
	}, [comments, massage, id])
	// --
	const [user_id, setUser_id] = useState()
	const [visibility, setVisibility] = useState(0)
	const [name, setName] = useState('')
	const [coutry, setCoutry] = useState(null)
	const [comen, setComen] = useState([])
	const [delComments, setDelComments] = useState([])
	// --
	const [openCreate, setOpenCrwate] = useState(false)
	const [openEdit, setOpenEdit] = useState(false)
	const [variant, setVariant] = useState(1)
	const [ids, seIds] = useState(1)
	// --
	const openPopUpCreateComent = (v, id) => {
		setVariant(v)
		seIds(id)
		setOpenCrwate(true)
	}
	const openPopUpEditComent = id => {
		seIds(id)
		setOpenEdit(true)
	}
	// --
	const deleteComments = (i, v) => {
		let arr = []
		let chr = false
		for (let f of comen) {
			if (f.id == i && !chr) {
				chr = true
				arr.push(f.id)
				continue
			}
			if (chr && f.variant > v) {
				arr.push(f.id)
			} else if (chr) {
				break
			}
		}
		setDelComments([...delComments, ...arr])
		setComen(
			comen.filter(b => {
				for (let i of arr) {
					if (i == b.id) return false
				}
				return true
			})
		)
	}
	const handleSubmit = event => {
		event.preventDefault()
		const com = comen.map((i, x) => {
			let answer = 'null'
			if (i.variant != 1) {
				for (let s = x - 1; s > -1; s--) {
					if (comen[s].variant < i.variant) {
						answer = s
						break
					}
				}
			}
			return {
				id: i.id,
				comments_id: +id,
				position: x,
				text: i.text,
				photo: i.photo_id ? i.photo_id : i.photo,
				variant: i.variant,
				answer: answer,
				fb_account_id: i.fb_account_id,
				like_0: i.like_0,
				like_1: i.like_1,
				like_2: i.like_2,
				like_3: i.like_3,
			}
		})
		const data = new FormData(event.currentTarget)
		const commentss = new FormData()
		comen.map(i => {
			commentss.append(i.photo_id, i.photo)
		})
		commentss.append('comments_id', id)
		commentss.append('name', data.get('name'))
		commentss.append('unicode', coutry)
		commentss.append('visibility', visibility)
		commentss.append('del', JSON.stringify(delComments))
		commentss.append('text', JSON.stringify(com))
		dispatch(updateComments(commentss))
	}
	// --
	const propsScheme = {
		name,
		comen,
		coutry,
		setName,
		setCoutry,
		openPopUpCreateComent,
		openPopUpEditComent,
		deleteComments,
		handleSubmit,
		visibility,
		setVisibility,
		user_id,
	}
	const propsPopUpCreate = {
		ids,
		variants: variant,
		comen,
		open: openCreate,
		setComen,
		setOpen: setOpenCrwate,
	}
	const propsPopUpEdit = {
		comen,
		setComen,
		open: openEdit,
		setOpen: setOpenEdit,
		ids,
	}
	return (
		<>
			<CommentScheme {...propsScheme} />
			<CommentPopUpCreate {...propsPopUpCreate} />
			<CommentPopUpEdit {...propsPopUpEdit} />
		</>
	)
}
