import SetapBot from "../pages/SetapBot";

export const SetapBotRouter = {
    path: "/setapbot",
    element: <SetapBot />,
    children: [
        // {
        //     index: true,
        //     element: <TeamProfileTable />,
        // },
    ],
};
