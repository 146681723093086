import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getBot,
	updateBotNotes,
	updateBotStatus,
} from '../../../store/botSlice'
import { getComments } from '../../../store/commentsSlice'
import { getAccounts } from '../../../store/accountsSlice'
import { useNavigate } from 'react-router-dom'
import Table from '../../Table/Table.jsx'
import { GridActionsCellItem } from '@mui/x-data-grid'
import {
	Delete,
	Feed,
	Info,
	NotStarted,
	Pause,
	Photo,
} from '@mui/icons-material'
import { InfoAcountPopUp } from '../../PopUp/InfoPopUp'
import NotesPopUp from '../../PopUp/NotesPopUp.jsx'

export default function BotTable() {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	// Получение данных из Redux-состояния
	const bot = useSelector(state => state.bot.bot)
	const accounts = useSelector(state => state.accounts.accounts)

	// Состояния компонента
	const [rows, setRows] = useState([])
	const [deleteID, setDeleteID] = useState()
	const [popAccountInfoOpen, setPopAccountInfoOpen] = useState(false)
	const [popNotesOpen, setPopNotesOpen] = useState(false)

	// Загрузка данных при монтировании компонента
	useEffect(() => {
		dispatch(getBot())
		dispatch(getComments())
		dispatch(getAccounts())
	}, [])

	// Обновление данных при изменении состояния ботов
	let relou = true
	useEffect(() => {
		let resBot = []
		for (let i of bot) {
			if (i.ready.split('_')[0] < 2) {
				if (relou) {
					relou = false
					setTimeout(() => {
						relou = true
						dispatch(getBot())
					}, 5000)
				}
			}
			let arr = { ...i }
			arr.dat = new Date(i.data)
			resBot.push(arr)
		}
		setRows(resBot)
	}, [bot])

	// Функция для получения имени аккаунта по его ID
	const getAccountNameById = id => {
		const account = accounts.find(acc => acc.id === id)
		return account
			? `${account.name} - ${account.gender} - ${account.unicode}`
			: ''
	}

	// Функция для получения заметок по ID бота
	const getBotNotesById = id => {
		const botItem = bot.find(b => b.id === id)
		return botItem ? botItem.comment : ''
	}

	// Функции для управления открытием и закрытием всплывающих окон
	const handleAccountInfoOpen = () => setPopAccountInfoOpen(true)
	const handleAccountInfoClose = () => setPopAccountInfoOpen(false)
	const handleNotesOpen = () => setPopNotesOpen(true)
	const handleNotesClose = () => setPopNotesOpen(false)

	// Функция для сохранения заметок
	const saveNotes = text => {
		dispatch(updateBotNotes({ bot_id: deleteID, text: text }))
	}

	// Рендер для колонок таблицы
	const renderNameCell = params => (
		<Box
			sx={{ cursor: 'pointer', textDecoration: 'underline' }}
			onClick={() => {
				navigate(`/comments/edit/${params.row.info}`)
			}}
		>
			{params.row.name}
		</Box>
	)

	const renderStatusCell = params => {
		const status = params.row.ready.split('_')[0]
		const liks = params.row.liks
		const queue = params.row.queue
		const info = params.row.ready.split('_')[2]
		return (
			<Box
				sx={{
					fontWeight: '800',
					color:
						status == 0
							? '#8E4FC2'
							: status == 1
							? '#D7D042'
							: status == 2
							? '#00934D'
							: status == 3 && '#F54F51',
				}}
			>
				{status == 0 && <>В очереди № {queue}</>}
				{status == 1 && <>{liks ? 'Ставит лайки' : 'Пишет пост'}</>}
				{status == 2 && <>Готово</>}
				{status == 3 && <>{info}</>}
			</Box>
		)
	}

	const renderAccountsCell = params => {
		const status = params.row.ready.split('_')[0]
		const account_id = params.row.ready.split('_')[1]
		return (
			<Box>
				{status == 3 && (
					<>
						{account_id && (
							<>
								{getAccountNameById(account_id)}
								<Button
									color='info'
									onClick={() => {
										navigate(`/accounts/edit/${account_id}`)
									}}
								>
									Edit
								</Button>
								<br />
							</>
						)}
					</>
				)}
			</Box>
		)
	}

	const renderPostCell = params => {
		const length = params.row.length
		return <Box>Пост №: {length + 1}</Box>
	}

	const renderInfoCell = params => {
		const status = params.row.ready.split('_')[0]
		const result = [
			<GridActionsCellItem
				icon={<Info color='info' />}
				label='Info'
				title='Accounts Use'
				onClick={() => {
					setDeleteID(params.row.info)
					handleAccountInfoOpen()
				}}
			/>,
			<GridActionsCellItem
				icon={<Feed color='info' />}
				title={params.row.comment}
				label='Notes'
				onClick={() => {
					setDeleteID(params.row.id)
					handleNotesOpen()
				}}
			/>,
		]
		if (status > 1) {
			result.push(
				<GridActionsCellItem
					icon={<Photo color='info' />}
					title={params.row.comment}
					label='Photo info'
					onClick={() => {
						window.open(
							`https://comentbot.trafficfamily.fun:5000/comentBot/bot_info/${params.row.id}.jpg`
						)
					}}
				/>
			)
		}
		return result
	}

	const renderActionsCell = params => {
		const id = params.row.id
		const status = params.row.ready.split('_')[0]
		const result = []
		if (status < 2) {
			result.push(
				<GridActionsCellItem
					icon={<Pause />}
					label='Pause'
					sx={{
						color: 'primary.main',
					}}
					onClick={() =>
						dispatch(
							updateBotStatus({
								bot_id: id,
								ready: '3__Преостановлено',
							})
						)
					}
				/>
			)
		}
		if (status == 3) {
			result.push(
				<GridActionsCellItem
					icon={<NotStarted />}
					label='Start'
					sx={{
						color: 'primary.main',
					}}
					onClick={() =>
						dispatch(
							updateBotStatus({
								bot_id: id,
								ready: 0,
							})
						)
					}
				/>
			)
		}
		if (status >= 2) {
			result.push(
				<GridActionsCellItem
					icon={<Delete />}
					label='Delete'
					sx={{
						color: 'primary.main',
					}}
					onClick={() =>
						dispatch(
							updateBotStatus({
								bot_id: id,
								ready: -1,
							})
						)
					}
				/>
			)
		}
		return result
	}
	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			type: 'numder',
			editable: false,
			flex: 0.5,
		},
		{
			field: 'name',
			headerName: 'Name',
			type: 'string',
			flex: 2,
			editable: false,
			renderCell: renderNameCell,
		},
		{
			field: 'status',
			headerName: 'Status',
			type: 'string',
			editable: false,
			flex: 1,
			renderCell: renderStatusCell,
		},
		{
			field: 'account_id',
			headerName: 'Accounts',
			type: 'string',
			flex: 2,
			editable: false,
			renderCell: renderAccountsCell,
		},
		{
			field: 'post',
			headerName: 'Post',
			type: 'string',
			flex: 2,
			editable: false,
			renderCell: renderPostCell,
		},
		{
			field: 'dat',
			headerName: 'Date Start',
			type: 'dateTime',
			flex: 2,
			editable: false,
		},
		{
			field: 'info',
			type: 'actions',
			headerName: 'Info',
			flex: 1,
			getActions: renderInfoCell,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			flex: 1,
			getActions: renderActionsCell,
		},
	]

	return (
		<>
			<Table rows={rows} columns={columns} />
			<InfoAcountPopUp
				open={popAccountInfoOpen}
				close={handleAccountInfoClose}
				comentId={deleteID}
			/>
			<NotesPopUp
				open={popNotesOpen}
				close={handleNotesClose}
				basText={getBotNotesById(deleteID)}
				save={saveNotes}
			/>
		</>
	)
}
