import {
	Box,
	Button,
	IconButton,
	MenuItem,
	TableCell,
	TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	copyComments,
	deleteComments,
	getComments,
} from '../../../store/commentsSlice'
import { useNavigate } from 'react-router-dom'
import { getAccounts } from '../../../store/accountsSlice'
import {
	ContentCopy,
	Delete,
	Info,
	OpenInNew,
	Person,
} from '@mui/icons-material'
import { GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid'
import СonsentPopUp from '../../PopUp/СonsentPopUp'
import StatusModel from '../../StatusModel/StatusModel'
import { InfoAcountPopUp } from '../../PopUp/InfoPopUp'
import Cookies from 'js-cookie'
import Table from '../../Table/Table.jsx'

export default function CommentTable() {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const comments = useSelector(state => state.comments.comments)

	const [rows, setRows] = useState([])
	const [popDeleteOpen, setPopDeleteOpen] = useState(false)
	const [popCopyOpen, setPopCopyOpen] = useState(false)
	const [popAccountInfoOpen, setPopAccountInfoOpen] = useState(false)
	const [deleteID, setDeleteID] = useState()
	const [name, setName] = useState()

	const [CountrySort, setCountrySort] = useState('All Country')
	const [arrCountrySort, setArrCountrySort] = useState([])
	const [StatusSort, setStatusSort] = useState('All Status')
	const [arrStatusSort, setArrStatusSort] = useState([
		'All Status',
		'Error',
		'Active',
	])
	const [usersSort, setUsersSort] = useState('All Users')
	const [usersArr, setUsersArr] = useState(['All Users'])

	useEffect(() => {
		let result = []
		let unicode = ['All Country']
		let us = ['All Users']
		for (let i of comments) {
			if (!unicode.includes(i.unicode)) unicode.push(i.unicode)
			if (!us.includes(i.user_name)) us.push(i.user_name)
			if (CountrySort !== 'All Country') {
				if (CountrySort !== i.unicode) continue
			}
			if (StatusSort !== 'All Status') {
				if (StatusSort === 'Error' && i.status === 0) continue
				if (StatusSort === 'Active' && i.status === 1) continue
			}
			if (usersSort !== 'All Users') {
				if (i.user_name !== usersSort) continue
			}
			result.push(i)
		}
		setArrCountrySort(unicode)
		setUsersArr(us)
		setRows(result)
	}, [comments, CountrySort, StatusSort, usersSort])

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			type: 'numder',
			editable: false,
			resizable: false,
			width: 50,
		},
		{
			field: 'name',
			headerName: 'Name',
			type: 'string',
			flex: 2,
			editable: false,
		},
		{
			field: 'unicode',
			headerName: 'Country',
			type: 'singleSelect',
			editable: false,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Status',
			type: 'singleSelect',
			editable: false,
			valueOptions: StatusModel.CommentStatus(0, true),
			flex: 2,
			renderCell: params => {
				const st = params.row.status
				return StatusModel.CommentStatus(st)
			},
		},
		{
			field: 'visibility',
			headerName: 'Hide',
			type: 'boolean',
			editable: false,
			resizable: false,
			width: 50,
		},
		{
			field: 'users',
			type: 'actions',
			headerName: 'Users',
			resizable: false,
			flex: 1,
			getActions: params => {
				const id = params.row.id
				const user_id = params.row.user_name
				const user_color = params.row.user_color
				return [
					<Person
						titleAccess={`${user_id}`}
						sx={{ color: `${user_color}`, background: '#f5eee5' }}
					/>,
				]
			},
		},
		{
			field: 'info',
			type: 'actions',
			headerName: 'Info',
			resizable: false,
			width: 50,
			getActions: params => [
				<GridActionsCellItem
					icon={<Info color='info' />}
					label='Info'
					className='textPrimary'
					onClick={() => {
						setDeleteID(params.row.id)
						popAccountInfoOpenOpen()
					}}
					color='inherit'
				/>,
			],
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			resizable: false,
			flex: 1,
			cellClassName: 'actions',
			getActions: params => {
				const id = params.row.id
				return [
					<GridActionsCellItem
						icon={<OpenInNew />}
						label='Edit'
						className='textPrimary'
						onClick={() => navigate(`edit/${id}`)}
						color='inherit'
					/>,
					<GridActionsCellItem
						icon={<ContentCopy />}
						label='Delete'
						onClick={handleCopyClick(id)}
						color='inherit'
					/>,
					<GridActionsCellItem
						icon={<Delete />}
						label='Delete'
						onClick={handleDeleteClick(id)}
						color='inherit'
					/>,
				]
			},
		},
	]

	// ===
	const popDeleteClose = () => setPopDeleteOpen(false)
	const popCopyClose = () => setPopCopyOpen(false)
	const popAccountInfoOpenClose = () => setPopAccountInfoOpen(false)
	const popAccountInfoOpenOpen = () => setPopAccountInfoOpen(true)
	const getCommentName = id => {
		for (let i of comments) {
			if (i.id == id) {
				return i.name
			}
		}
	}
	const handleDeleteClick = id => () => {
		setName(getCommentName(id))
		setDeleteID(id)
		setPopDeleteOpen(true)
	}
	const handleCopyClick = id => () => {
		setName(getCommentName(id))
		setDeleteID(id)
		setPopCopyOpen(true)
	}
	// ===

	function QuickSearchToolbar() {
		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-around',
					gap: 2,
					p: 0.5,
					// pb: 0,
					background: '#eee',
				}}
			>
				<GridToolbarQuickFilter
					variant='outlined'
					sx={{
						width: '50%',
						background: '#fff',
						borderRadius: 1,
						pb: 0,
					}}
				/>
				<TextField
					sx={{
						width: '25%',
						background: '#fff',
						borderRadius: 1,
						pb: 0,
					}}
					onChange={e => setStatusSort(e.target.value)}
					value={StatusSort}
					label='Status'
					select
				>
					{arrStatusSort.map(i => (
						<MenuItem key={i} value={i}>
							{i}
						</MenuItem>
					))}
				</TextField>
				<TextField
					sx={{
						width: '50%',
						background: '#fff',
						borderRadius: 1,
						pb: 0,
					}}
					onChange={e => setCountrySort(e.target.value)}
					value={CountrySort}
					label='Country'
					select
				>
					{arrCountrySort.map(i => (
						<MenuItem key={i} value={i}>
							{i}
						</MenuItem>
					))}
				</TextField>
				<TextField
					sx={{
						width: '50%',
						background: '#fff',
						borderRadius: 1,
						pb: 0,
					}}
					onChange={e => setUsersSort(e.target.value)}
					value={usersSort}
					label='Users'
					select
				>
					{usersArr.map(i => (
						<MenuItem key={i} value={i}>
							{i}
						</MenuItem>
					))}
				</TextField>
			</Box>
		)
	}

	return (
		<>
			<Button
				onClick={() => navigate('create')}
				color='success'
				variant='contained'
			>
				Добавить
			</Button>
			<Table
				rows={rows}
				columns={columns}
				slots={{ toolbar: QuickSearchToolbar }}
			/>
			<СonsentPopUp
				open={popDeleteOpen}
				close={popDeleteClose}
				title={'Удалить Коментарий'}
				message={name}
				success={() => {
					popDeleteClose()
					dispatch(deleteComments(deleteID))
				}}
				error={popDeleteClose}
			/>
			<СonsentPopUp
				open={popCopyOpen}
				close={popCopyClose}
				title={'Копировать Коментарий'}
				message={name}
				success={() => {
					popCopyClose()
					dispatch(copyComments({ comment_id: deleteID }))
				}}
				error={popCopyClose}
			/>
			<InfoAcountPopUp
				open={popAccountInfoOpen}
				close={popAccountInfoOpenClose}
				comentId={deleteID}
			/>
		</>
	)
}
